/*
  @page Settings
*/

//Import require libraries
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import axios from "axios";
import MuiSelect from "@mui/material/Select";
import Select from "react-select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Switch } from "@material-ui/core";
import Helper from "./../../util/Helper";
import DatePicker from "react-datepicker";
import InputLabel2 from "@mui/material/InputLabel";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import TextField from "@mui/material/TextField";
import { QrCode } from "@mui/icons-material";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import InputAdornment from "@mui/material/InputAdornment";
import PrecentageIcon from "@mui/icons-material/PercentOutlined";
import SelectMui from "@mui/material/Select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import InputLabel from "@mui/material/InputLabel";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

/*
  @function Display alert for error message
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/*
  @const Page styles
*/
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  disclaimerTitle: {
    color: "black",
    marginTop: "-10px",
  },
  margintopid: {
    marginTop: "30px !important",
  },
};

const useStyles = makeStyles(styles);

/*
  @const State array

  */
const country = [{ label: "USA", value: "USA" }];
const actions = [
  { label: "Alabama", value: "Alabama" },
  { label: "Alaska", value: "Alaska" },
  { label: "Arizona", value: "Arizona" },
  { label: "Arkansas", value: "Arkansas" },
  { label: "California", value: "California" },
  { label: "Colorado", value: "Colorado" },
  { label: "Connecticut", value: "Connecticut" },
  { label: "Delaware", value: "Delaware" },
  { label: "Florida", value: "Florida" },
  { label: "Georgia", value: "Georgia" },
  { label: "Hawaii", value: "Hawaii" },
  { label: "Idaho", value: "Idaho" },
  { label: "Illinois", value: "Illinois" },
  { label: "Indiana", value: "Indiana" },
  { label: "Iowa", value: "Iowa" },
  { label: "Kansas", value: "Kansas" },
  { label: "Kentucky", value: "Kentucky" },
  { label: "Louisiana", value: "Louisiana" },
  { label: "Maine", value: "Maine" },
  { label: "Maryland", value: "Maryland" },
  { label: "Massachusetts", value: "Massachusetts" },
  { label: "Michigan", value: "Michigan" },
  { label: "Minnesota", value: "Minnesota" },
  { label: "Mississippi", value: "Mississippi" },
  { label: "Missouri", value: "Missouri" },
  { label: "Montana", value: "Montana" },
  { label: "Nebraska", value: "Nebraska" },
  { label: "Nevada", value: "Nevada" },
  { label: "New Hampshire", value: "New Hampshire" },
  { label: "New Jersey", value: "New Jersey" },
  { label: "New Mexico", value: "New Mexico" },
  { label: "New York", value: "New York" },
  { label: "North Carolina", value: "North Carolina" },
  { label: "North Dakota", value: "North Dakota" },
  { label: "Ohio", value: "Ohio" },
  { label: "Oklahoma", value: "Oklahoma" },
  { label: "Oregon	Salem", value: "Oregon	Salem" },
  { label: "Pennsylvania", value: "Pennsylvania" },
  { label: "Puerto Rico", value: "Puerto Rico" },
  { label: "Rhode Island", value: "Rhode Island" },
  { label: "South Carolina", value: "South Carolina" },
  { label: "South Dakota", value: "South Dakota" },
  { label: "Tennessee", value: "Tennessee" },
  { label: "Texas", value: "Texas" },
  { label: "US Virgin Islands", value: "US Virgin Islands" },
  { label: "Utah", value: "Utah" },
  { label: "Vermont", value: "Vermont" },
  { label: "Virginia", value: "Virginia" },
  { label: "Washington", value: "Washington" },
  { label: "West Virginia", value: "West Virginia" },
  { label: "Wiscons", value: "Wiscons" },
  { label: "Wyoming", value: "Wyoming" },
];

/*
  @function Settings page logic
*/
export default function UserProfile() {
  const classes = useStyles();
  const [adminEmail, setAdminEmail] = useState("");
  const [email, setEmail] = useState("");
  const [transitPassword, setTransitPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [token, setToken] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [state, setState] = useState("");
  const [contactName, setContactName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [taxIdNumber, setTaxIdNumber] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [settingData, setStttingData] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [sessionTimeout, setSesstionTimeout] = useState(null);
  const [passcode, setPasscode] = useState(null);
  const [sign_footer_line, setSignFooterLine] = useState(null);
  const [footer_line, setFooterLine] = useState(null);
  const [batchAtoEmail, setBatchAutoEmail] = useState(false);
  const [website, setWebsite] = useState("");
  const [merchant_id, setMerchantID] = useState("");
  const [merchant_cat_code, setMerchantCatCode] = useState("");
  const [sendIvuData, setSendIvuData] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [time, setTime] = React.useState("");
  const [ath_path, setATHPath] = React.useState("");
  const [ath_public_token, setATHPublicToken] = React.useState("");
  const [ath_private_token, setATHPrivateToken] = React.useState("");
  const [ath_webhook_url, setATHWebhookURL] = React.useState("");
  const [disclaimerDialog, setDisclaimerDialog] = useState(false);
  const [smsDailog, setSmsDailog] = useState(false);
  const [emailDailog, setEmailDailog] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [ath_send_email, setAthSendEmail] = useState("");
  const [autoBatchCloseTime, setAutoBatchCloseTime] = useState("");
  const [zipcodeLoding, setZipcodeLoding] = useState(false);
  const [transitMerchantId, setTransitMerchantId] = useState("");
  const [transitdeviceId, setTransitDeviceId] = useState("");
  const [transittsepdeviceId, setTransitTSEPDeviceId] = useState("");
  const [transituserId, setTransitUserId] = useState("");
  const [transitDeveloperId, setTransitDeveloperId] = useState("");
  

  const [newTransitMerchantId, setNewTransitMerchantId] = useState("");
  const [newTransitdeviceId, setNewTransitDeviceId] = useState("");
  const [newTransittsepdeviceId, setNewTransitTSEPDeviceId] = useState("");
  const [newTransituserId, setNewTransitUserId] = useState("");
  const [newTransitDeveloperId, setNewTransitDeveloperId] = useState("");
  
  const [newTransitPassword, setNewTransitPassword] = useState("");

  const [cvvCodeN, setCvvCodeN] = useState(false);
  const [cvvCodeP, setCvvCodeP] = useState(false);
  const [cvvCodeS, setCvvCodeS] = useState(false);
  const [cvvCodeU, setCvvCodeU] = useState(false);

  // AVS CODE
  const [avsCode0, setAVSCode0] = useState(false);
  const [avsCode1, setAVSCode1] = useState(false);
  const [avsCode2, setAVSCode2] = useState(false);
  const [avsCode3, setAVSCode3] = useState(false);
  const [avsCode4, setAVSCode4] = useState(false);
  const [avsCode5, setAVSCode5] = useState(false);
  const [avsCode6, setAVSCode6] = useState(false);
  const [avsCode7, setAVSCode7] = useState(false);
  const [avsCode8, setAVSCode8] = useState(false);

  const [avsCodeA, setAVSCodeA] = useState(false);
  const [avsCodeB, setAVSCodeB] = useState(false);
  const [avsCodeC, setAVSCodeC] = useState(false);
  const [avsCodeD, setAVSCodeD] = useState(false);
  const [avsCodeF, setAVSCodeF] = useState(false);
  const [avsCodeG, setAVSCodeG] = useState(false);
  const [avsCodeI, setAVSCodeI] = useState(false);
  const [avsCodeM, setAVSCodeM] = useState(false);
  const [avsCodeN, setAVSCodeN] = useState(false);
  const [avsCodeP, setAVSCodeP] = useState(false);
  const [avsCodeR, setAVSCodeR] = useState(false);
  const [avsCodeS, setAVSCodeS] = useState(false);
  const [avsCodeU, setAVSCodeU] = useState(false);
  const [avsCodeW, setAVSCodeW] = useState(false);
  const [avsCodeX, setAVSCodeX] = useState(false);
  const [avsCodeY, setAVSCodeY] = useState(false);
  const [avsCodeZ, setAVSCodeZ] = useState(false);

  const [transitChkuserId, setTransitChkUserId] = useState("");
  const [transitChkMerchantId, setTransitChkMerchantId] = useState("");
  // const [transitTransactionKey, setTransitTransactionKey] = useState("");
  const [groupId, setGroupId] = useState("");
  const [groupMenuList, setGroupMenuList] = useState({
    1: "MagicPay USA",
    2: "MagicPay Puerto Rico",
    3: "MagicPay US Virgin Islands",
    4: "MagicPay Mexico",
  });

  const autoCloseBatchTime = [
    "00:30",
    "01:30",
    "02:30",
    "03:30",
    "04:30",
    "05:30",
    "06:30",
    "07:30",
    "08:30",
    "09:30",
    "10:30",
    "11:30",
    "12:30",
    "13:30",
    "14:30",
    "15:30",
    "16:30",
    "17:30",
    "18:30",
    "19:30",
    "20:30",
    "21:30",
    "22:30",
    "23:30",
  ];

  const [showGroupMenuList, setShowGroupMenuList] = useState(false);

  const handleChange = (event) => {
    setTime(event.target.value);
  };
  const ColoredLine = ({ color }) => (
    <hr
      style={{
        color: color,
        backgroundColor: color,
        height: 2,
        marginTop: "30px",
      }}
    />
  );

  const showTransitField = (e) => {
    setProcessor(e.target.value);
    if (e.target.value == "TSYS TransIT") {
      document.getElementById("transitInfo").style.display = "block";
      setProcessorNote(
        <span>
          <b>Note: </b>Please check below TransIT Information properly for API
          calls
        </span>
      );
    } else {
      document.getElementById("transitInfo").style.display = "none";
      setProcessorNote("");
    }
  };

  /*
    @function Setting form submit
  */
  function handleSubmit(event) {
    event.preventDefault();

    if (!validURL(String(website)) && website != "" && website != null) {
      // Check web url is valid or not
      setError(true);
      setErrorMsg("Please Enter Valid URL");
      return false;
    }
    if (passcode.length < 4) {
      setError(true);
      setErrorMsg("passcode must be 4 digit long");
      return false;
    }
    var data = "";
    if (state === "Puerto Rico") {
      // Check selected state is Puerto Rico or not
      data = {
        business_name: businessName,
        email: email,
        street_address: streetAddress,
        city: city,
        state: selectedState,
        country: selectedCountry,
        zip: zip,
        phone_number: phoneNumber,
        passcode: passcode,
        session_timeout: sessionTimeout,
        website: website,
        name: contactName,
        offline_push_days: offline_push_days,
        accept_ath: enableATHBusiness ? 1 : 0,
      };
    } else {
      data = {
        business_name: businessName,
        email: email,
        street_address: streetAddress,
        city: city,
        state: selectedState,
        country: selectedCountry,
        zip: zip,
        phone_number: phoneNumber,
        passcode: passcode,
        evertec_flag: "0",
        puerto_rico_number: "",
        session_timeout: sessionTimeout,
        website: website,
        name: contactName,
        offline_push_days: offline_push_days,
        accept_ath: enableATHBusiness ? 1 : 0,
      };
    }
    if (localStorage.getItem("token")) {
      // Check data is available in localstorage or not
      data.admin_token = localStorage.getItem("token");
      data.merchant_id = merchant_id;
      data.merchant_cat_code = merchant_cat_code;
      data.group_id = groupId;
    }

    data.processor = processor;
    /*
      @function For url validation
    */
    function validURL(str) {
      const regex = new RegExp(
        /^((https?):\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      );
      return regex.test(str);
    }
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };
    data.parent_id = localStorage.getItem("parent_id");
    data.location_id = localStorage.getItem("location_id");
    axios
      .post(window.apiUrl + "save_setting", data, {
        headers: headers,
      })
      .then((response) => {
        if (response) {
          setGetLogo(response.data.settingData.logo);
          if (response.data.error) {
            //Check data updated successfully or any error generate
            setErrorMsg(response.data.error);
            setError(true);
          } else {
            setSuccessMsg("Setting Updated Successfully!");
            setSuccess(true);
          }

          setAskCustomerName(response.data.settingData.ask_customer_name);
          setAskTableNumber(response.data.settingData.ask_table_number);
          setPrintCustomerName(response.data.settingData.print_customer_name);
          setPrintTableNumber(response.data.settingData.print_table_number);
          setEmail(response.data.settingData.email);
          setBusinessName(response.data.settingData.business_name);
          setStreetAddress(response.data.settingData.street_address);
          setCity(response.data.settingData.city);
          setZip(String(response.data.settingData.zip).padStart(5, "0"));
          setState(response.data.settingData.state);
          setPhoneNumber(response.data.settingData.phone_number);
          setTaxIdNumber(response.data.settingData.puerto_rico_number);
          setStttingData(true);
          setSesstionTimeout(response.data.settingData.session_timeout);
          setPasscode(response.data.settingData.passcode);
          setIvuSwitch(response.data.settingData.evertec_flag);
          setSignFooterLine(response.data.settingData.sign_footer_line);
          // setEnableATHBusiness(response.data.settingData.accept_ath);
          // handleEnableATHBusiness(response.data.settingData.accept_ath);
          setFooterLine(response.data.settingData.footer_line);
          setSignFooterSwitch(
            response.data.settingData.receipt_signature_footer
          );
          setBatchAutoEmail(response.data.settingData.auto_batch_email);

          var batchAutoCloseTime =
            response.data.settingData.auto_batch_close_time.substring(0, 5);

          setAutoBatchCloseTime(batchAutoCloseTime);
          setWebsite(response.data.settingData.website);
          setMerchantID(response.data.settingData.merchant_id);
          setSendIvuData(response.data.settingData.send_ivu_data);
          setMerchantCatCode(response.data.settingData.merchant_cat_code);
          setContactName(response.data.settingData.name);
          setGetLogo(response.data.settingData.logo);
          setOfflinePushDays(response.data.settingData.offline_push_days);
          setGetQrLogo(response.data.settingData.ath_qr_code_img);

          var EnableAthValue =
            response.data.settingData.accept_ath == 1 ? true : false;
          setEnableATHBusiness(EnableAthValue);
          handleEnableATHBusiness(EnableAthValue);

          if (response.data.settingData.ath_qr_code_img) {
            document.getElementById("labelForQrCodeUploadBtn").style.display =
              "none";
            document.getElementById("qrCodeImage").style.display = "block";
            document.getElementById("deleteQrCodeImageBtn").style.display =
              "block";
          }
          setATHPath(response.data.settingData.ath_business_path);
          setATHWebhookURL(
            "https://api.magicpaypos.com/api/ath/" +
              response.data.settingData.ath_business_path
          );
          setATHPublicToken(response.data.settingData.ath_public_token);
          setATHPrivateToken(response.data.settingData.ath_private_token);

          // other setting switch value set
          setAcceptDebitCard(
            response.data.settingData.accept_debit_cards == 1 ? true : false
          );
          setAcceptCreditCard(
            response.data.settingData.accept_credit_cards == 1 ? true : false
          );
          setAcceptCash(
            response.data.settingData.accept_cash_cards == 1 ? true : false
          );
          setAcceptEbt(
            response.data.settingData.accept_ebt == 1 ? true : false
          );
          setShowCustomTab(
            response.data.settingData.custom_tab == 1 ? true : false
          );
          setShowItemTab(
            response.data.settingData.item_tab == 1 ? true : false
          );
          setEnableQuickPayment(
            response.data.settingData.enable_quick_payment == 1 ? true : false
          );
          setIncludeTax(
            response.data.settingData.include_tax == 1 ? true : false
          );
          setRemoveFee(
            response.data.settingData.remove_fee_chk_box == 1 ? true : false
          );
          setEnableCashDiscoiuntProgram(
            response.data.settingData.cash_discount_program == 1 ? true : false
          );
          setPromptSignatureonPINDebit(
            response.data.settingData.prompt_sign_on_pin_debit == 1
              ? true
              : false
          );
          setCardHolderSignature(response.data.settingData.card_holder_sign);
          setCashDiscountAmount(
            response.data.settingData.cash_discount_percentage
          );
          setApplyFeeCreditCard(
            response.data.settingData.applay_non_cash_fee_to_credit
          );
          setApplyFeeDebitCard(
            response.data.settingData.applay_non_cash_fee_to_debit
          );
          setGroupId(response.data.settingData.group_id);
          //Transit Setting Value set
          setTransitMerchantId(response.data.settingData.transit_merchant_id);
          setTransitDeviceId(response.data.settingData.transit_device_id);
          setTransitTSEPDeviceId(
            response.data.settingData.transit_tsep_device_id
          );
          setTransitUserId(response.data.settingData.transit_user_id);
          setTransitDeveloperId(response.data.settingData.transit_developer_id);
          
          setTransItPlateform(
            response.data.settingData.transit_plateform == "1" ? true : false
          );

          setTransitChkUserId(response.data.settingData.transit_user_id);
          setTransitChkMerchantId(
            response.data.settingData.transit_merchant_id
          );
          // setTransitTransactionKey(response.data.settingData.transit_transaction_key);

          setProcessor(response.data.settingData.processor);

          if (response.data.settingData.processor === "TSYS TransIT") {
            document.getElementById("transitInfo").style.display = "block";
            setProcessorNote(
              <span>
                <b>Note: </b>Please check below TransIT Information properly for
                API calls
              </span>
            );
            var cvvRuleData = JSON.parse(
              response.data.settingData.vm_void_rules
            );
            setCvvCodeN(cvvRuleData.cvv_code_n == "1" ? true : false);
            setCvvCodeP(cvvRuleData.cvv_code_p == "1" ? true : false);
            setCvvCodeS(cvvRuleData.cvv_code_s == "1" ? true : false);
            setCvvCodeU(cvvRuleData.cvv_code_u == "1" ? true : false);
            setAVSCode0(cvvRuleData.avs_code_0 == "1" ? true : false);
            setAVSCode1(cvvRuleData.avs_code_1 == "1" ? true : false);
            setAVSCode2(cvvRuleData.avs_code_2 == "1" ? true : false);
            setAVSCode3(cvvRuleData.avs_code_3 == "1" ? true : false);
            setAVSCode4(cvvRuleData.avs_code_4 == "1" ? true : false);
            setAVSCode5(cvvRuleData.avs_code_5 == "1" ? true : false);
            setAVSCode6(cvvRuleData.avs_code_6 == "1" ? true : false);
            setAVSCode7(cvvRuleData.avs_code_7 == "1" ? true : false);
            setAVSCode8(cvvRuleData.avs_code_8 == "1" ? true : false);
            setAVSCodeA(cvvRuleData.avs_code_A == "1" ? true : false);
            setAVSCodeB(cvvRuleData.avs_code_B == "1" ? true : false);
            setAVSCodeC(cvvRuleData.avs_code_C == "1" ? true : false);
            setAVSCodeD(cvvRuleData.avs_code_D == "1" ? true : false);
            setAVSCodeF(cvvRuleData.avs_code_F == "1" ? true : false);
            setAVSCodeG(cvvRuleData.avs_code_G == "1" ? true : false);
            setAVSCodeI(cvvRuleData.avs_code_I == "1" ? true : false);
            setAVSCodeM(cvvRuleData.avs_code_M == "1" ? true : false);
            setAVSCodeN(cvvRuleData.avs_code_N == "1" ? true : false);
            setAVSCodeP(cvvRuleData.avs_code_P == "1" ? true : false);
            setAVSCodeR(cvvRuleData.avs_code_R == "1" ? true : false);
            setAVSCodeS(cvvRuleData.avs_code_S == "1" ? true : false);
            setAVSCodeU(cvvRuleData.avs_code_U == "1" ? true : false);
            setAVSCodeW(cvvRuleData.avs_code_W == "1" ? true : false);
            setAVSCodeX(cvvRuleData.avs_code_X == "1" ? true : false);
            setAVSCodeY(cvvRuleData.avs_code_Y == "1" ? true : false);
            setAVSCodeZ(cvvRuleData.avs_code_Z == "1" ? true : false);
          } else {
            document.getElementById("transitInfo").style.display = "none";
            setProcessorNote("");
          }

          if (response.data.settingData.cash_discount_program == 0) {
            document.getElementById("tfDiscount").style.display = "none";
            document.getElementById("swAllowRemoveFee").style.display = "none";
          }

          let report_start_time =
            response.data.settingData.report_start_time.split(":");
          let t = new Date();
          t.setHours(report_start_time[0]);
          t.setMinutes(report_start_time[1]);
          t.setSeconds(0);
          setStartDate(t);
        }
      })
      .catch((error) => {
        setErrorMsg("Fail To Update Settings.");
        setError(true);
        return error;
      });
  }

  /*
   @function Receipt layout form submit
  */
  function handlecashDiscountProgramSubmit(event) {
    event.preventDefault();
    if (parseFloat(cashDiscountAmount) <= 3.0) {
      var formData = new FormData();

      formData.append(
        "cash_discount_program",
        enableCashDiscountProgram ? 1 : 0
      );
      formData.append("cash_discount_percentage", cashDiscountAmount);
      formData.append("remove_fee_chk_box", remove_fee_chk_box ? 1 : 0);
      formData.append(
        "applay_non_cash_fee_to_credit",
        applyFeeCreditCard ? 1 : 0
      );
      formData.append(
        "applay_non_cash_fee_to_debit",
        applyFeeDebitCard ? 1 : 0
      );
      formData.append("parent_id", localStorage.getItem("parent_id"));
      formData.append("location_id", localStorage.getItem("location_id"));

      const headers = {
        Authorization: "Bearer " + localStorage.getItem("merchantToken"),
        "Content-Type": "multipart/form-data",
      };
      axios
        .post(window.apiUrl + "save_setting", formData, {
          headers: headers,
        })
        .then((response) => {
          if (response) {
            if (response.data.error) {
              //Check data updated successfully or any error generate
              setErrorMsg(response.data.error);
              setError(true);
            } else {
              setSuccessMsg("Setting Updated Successfully!");
              setSuccess(true);
              signFooterSwitch ? "" : setSignFooterLine("");
            }
          }
        })
        .catch((error) => {
          setErrorMsg("Fail To Update Settings.");
          setError(true);

          return error;
        });
    } else {
      setErrorMsg("Can't add more than 3.00%");
      setError(true);
    }
  }

  /*
   @function Receipt layout form submit
  */
  function handleReceiptLayoutSubmit(event) {
    event.preventDefault();
    var formData = new FormData();
    if (deleteLogoCheck == true) {
      formData.append("logo", "");
      setDeleteLogoCheck(false);
    } else {
      if (logo) {
        formData.append("logo", logo);
      }
    }
    // auto_batch_close_time
    formData.append(
      "sign_footer_line",
      sign_footer_line ? sign_footer_line : ""
    );
    formData.append("footer_line", footer_line);
    formData.append("receipt_signature_footer", signFooterSwitch ? 1 : 0);
    formData.append(
      "auto_batch_email",
      batchAtoEmail === false ? "0" : batchAtoEmail
    );
    formData.append(
      "auto_batch_close_time",
      autoBatchCloseTime ? autoBatchCloseTime : ""
    );
    formData.append("print_customer_name", print_customer_name ? 1 : 0);
    formData.append("print_table_number", print_table_number ? 1 : 0);
    formData.append("parent_id", localStorage.getItem("parent_id"));
    formData.append("location_id", localStorage.getItem("location_id"));

    const data = {
      sign_footer_line: signFooterSwitch ? sign_footer_line : "",
      footer_line: footer_line,
      receipt_signature_footer: signFooterSwitch,
      auto_batch_email: batchAtoEmail === false ? "0" : batchAtoEmail,
      print_customer_name: print_customer_name,
      print_table_number: print_table_number,
      logo: logo,
    };
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
      "Content-Type": "multipart/form-data",
    };
    axios
      .post(window.apiUrl + "save_setting", formData, {
        headers: headers,
      })
      .then((response) => {
        if (response) {
          if (response.data.error) {
            //Check data updated successfully or any error generate
            setErrorMsg(response.data.error);
            setError(true);
          } else {
            setSuccessMsg("Setting Updated Successfully!");
            setSuccess(true);
            signFooterSwitch ? "" : setSignFooterLine("");
          }
          location.reload();
        }
      })
      .catch((error) => {
        setErrorMsg("Fail To Update Settings.");
        setError(true);

        return error;
      });
  }

  /*
   @function Report time form submit
  */
  function handleReportTimeSubmit(event) {
    event.preventDefault();
    let h = parseInt(startDate.getHours()) == 0 ? "00" : startDate.getHours();
    let m =
      parseInt(startDate.getMinutes()) == 0 ? "00" : startDate.getMinutes();
    var t = h + ":" + m + ":00";
    const data = { report_start_time: t };
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };
    data.parent_id = localStorage.getItem("parent_id");
    data.location_id = localStorage.getItem("location_id");
    axios
      .post(window.apiUrl + "save_setting", data, {
        headers: headers,
      })
      .then((response) => {
        if (response) {
          if (response.data.error) {
            //Check data updated successfully or any error generate
            setErrorMsg(response.data.error);
            setError(true);
          } else {
            setSuccessMsg("Setting Updated Successfully!");
            setSuccess(true);
            signFooterSwitch ? "" : setSignFooterLine("");
          }
        }
      })
      .catch((error) => {
        setErrorMsg("Fail To Update Settings.");
        setError(true);
        return error;
      });
  }

  /*
   @function Report time form submit
  */
  function handleIVUSubmit(event) {
    event.preventDefault();
    const data = {
      evertec_flag: ivuSwitch ? 1 : 0,
      puerto_rico_number: taxIdNumber.replace("-", "").slice(0, 11),
      send_ivu_data: sendIvuData ? 1 : 0,
    };
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };
    data.parent_id = localStorage.getItem("parent_id");
    data.location_id = localStorage.getItem("location_id");
    axios
      .post(window.apiUrl + "save_setting", data, {
        headers: headers,
      })
      .then((response) => {
        if (response) {
          if (response.data.error) {
            //Check data updated successfully or any error generate
            setErrorMsg(response.data.error);
            setError(true);
          } else {
            setSuccessMsg("Setting Updated Successfully!");
            setSuccess(true);
          }
        }
      })
      .catch((error) => {
        setErrorMsg("Fail To Update Settings.");
        setError(true);
        return error;
      });
  }
  /*
  @function Other setting form submit
*/
  function handleOtherSettingSubmit(event) {
    event.preventDefault();
    const data = {
      accept_debit_cards: accept_debit_cards === true ? 1 : 0,
      accept_credit_cards: accept_credit_cards === true ? 1 : 0,
      accept_cash_cards: accept_cash_cards === true ? 1 : 0,
      accept_ebt: accept_ebt === true ? 1 : 0,
      accept_ath: enableATHBusiness === true ? 1 : 0,
      custom_tab: custom_tab === true ? 1 : 0,
      item_tab: item_tab === true ? 1 : 0,
      enable_quick_payment: enable_quick_payment === true ? 1 : 0,
      include_tax: include_tax === true ? 1 : 0,
      ask_customer_name: ask_customer_name === true ? 1 : 0,
      ask_table_number: ask_table_number === true ? 1 : 0,
      prompt_sign_on_pin_debit: promptSignatureonPINDebit === true ? 1 : 0,
      card_holder_sign: cardHolderSignature,
    };
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };
    data.parent_id = localStorage.getItem("parent_id");
    data.location_id = localStorage.getItem("location_id");
    axios
      .post(window.apiUrl + "save_setting", data, {
        headers: headers,
      })
      .then((response) => {
        if (response) {
          if (response.data.error) {
            //Check data updated successfully or any error generate
            setErrorMsg(response.data.error);
            setError(true);
          } else {
            setSuccessMsg("Setting Updated Successfully!");
            setSuccess(true);
          }
        }
      })
      .catch((error) => {
        setErrorMsg("Fail To Update Settings.");
        setError(true);
        return error;
      });
  }

  function handleATHSettingSubmit(event) {
    event.preventDefault();
    console.log(QrCode);
    var formData = new FormData();
    if (QrCode) {
      formData.append("ath_qr_code_img", qrCode);
    }

    formData.append("ath_business_path", ath_path);
    formData.append("ath_public_token", ath_public_token);
    formData.append("ath_private_token", ath_private_token);
    formData.append("accept_ath", enableATHBusiness ? 1 : 0);
    formData.append("parent_id", localStorage.getItem("parent_id"));
    formData.append("location_id", localStorage.getItem("location_id"));

    const data = {
      ath_business_path: ath_path,
      ath_public_token: ath_public_token,
      ath_private_token: ath_private_token,
    };
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };
    data.parent_id = localStorage.getItem("parent_id");
    data.location_id = localStorage.getItem("location_id");
    axios
      .post(window.apiUrl + "save_setting", formData, {
        headers: headers,
      })
      .then((response) => {
        if (response) {
          if (response.data.error) {
            //Check data updated successfully or any error generate
            setErrorMsg(response.data.error);
            setError(true);
          } else {
            setSuccessMsg("Setting Updated Successfully!");
            setSuccess(true);
          }
        }
      })
      .catch((error) => {
        setErrorMsg("Fail To Update Settings.");
        setError(true);
        return error;
      });
  }

  function handlePasswordUpdateSubmit(event) {
    event.preventDefault();
    if (password === "" || confirmPassword === "") {
      setErrorMsg("password field is empty!");
      setError(true);
      return;
    }
    if (password !== confirmPassword) {
      setErrorMsg("password and confirm password are not match!");
      setError(true);
      return;
    }
    var formData = new FormData();
    formData.append("transit_password", password);
    if (localStorage.getItem("token")) {
      // Check data is available in localstorage or not
      formData.append("admin_token", localStorage.getItem("token"));
    }
    formData.append("transit_plateform", transItPlateform ? "1" : "0");
    formData.append("parent_id", localStorage.getItem("parent_id"));
    formData.append("location_id", localStorage.getItem("location_id"));

    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };

    axios
      .post(window.apiUrl + "save_setting", formData, {
        headers: headers,
      })
      .then((response) => {
        if (response) {
          if (response.data.error) {
            //Check data updated successfully or any error generate
            setErrorMsg(response.data.error);
            setError(true);
          } else {
            setSuccessMsg("Setting Updated Successfully!");
            setSuccess(true);
          }
        }
      })
      .catch((error) => {
        setErrorMsg("Fail To Update Settings.");
        setError(true);
        return error;
      });
  }

  function handleNewTransitSubmit(event) {
    event.preventDefault();

    var formData = new FormData();
    formData.append("transit_plateform", transItPlateform ? "1" : "0");
    formData.append("transit_merchant_id", newTransitMerchantId);
    formData.append("transit_device_id", newTransitdeviceId);
    formData.append("transit_tsep_device_id", newTransittsepdeviceId);
    formData.append("transit_user_id", newTransituserId);
    formData.append("transit_developer_id", newTransitDeveloperId);
    
    // formData.append("transit_transaction_key", transitTransactionKey);
    if (localStorage.getItem("token")) {
      // Check data is available in localstorage or not
      formData.append("admin_token", localStorage.getItem("token"));
    }
    if (newTransitPassword !== "") {
      // Check data is available in localstorage or not
      formData.append("transit_password", newTransitPassword);
    }
    formData.append("parent_id", localStorage.getItem("parent_id"));
    formData.append("location_id", localStorage.getItem("location_id"));

    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };

    axios
      .post(window.apiUrl + "update_transit_info", formData, {
        headers: headers,
      })
      .then((response) => {
        if (response) {
          if (response.data.error) {
            //Check data updated successfully or any error generate
            setErrorMsg(response.data.error);
            setError(true);
          } else {
            setSuccessMsg("Setting Updated Successfully!");
            setSuccess(true);
            location.reload();
          }
        }
      })
      .catch((error) => {
        setErrorMsg("Fail To Update Settings.");
        setError(true);
        return error;
      });
  }

  function handleTransitSubmit(event) {
    event.preventDefault();

    var formData = new FormData();
    formData.append("transit_merchant_id", transitMerchantId);
    formData.append("transit_device_id", transitdeviceId);
    formData.append("transit_tsep_device_id", transittsepdeviceId);
    formData.append("transit_user_id", transituserId);
    formData.append("transit_developer_id", transitDeveloperId);
    
    formData.append("transit_plateform", transItPlateform ? "1" : "0");
    // formData.append("transit_transaction_key", transitTransactionKey);
    if (localStorage.getItem("token")) {
      // Check data is available in localstorage or not
      formData.append("admin_token", localStorage.getItem("token"));
    }
    if (transitPassword !== "") {
      // Check data is available in localstorage or not
      formData.append("transit_password", transitPassword);
    }
    formData.append("parent_id", localStorage.getItem("parent_id"));
    formData.append("location_id", localStorage.getItem("location_id"));

    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };

    axios
      .post(window.apiUrl + "save_setting", formData, {
        headers: headers,
      })
      .then((response) => {
        if (response) {
          if (response.data.error) {
            //Check data updated successfully or any error generate
            setErrorMsg(response.data.error);
            setError(true);
          } else {
            setSuccessMsg("Setting Updated Successfully!");
            setSuccess(true);
          }
        }
      })
      .catch((error) => {
        setErrorMsg("Fail To Update Settings.");
        setError(true);
        return error;
      });
  }

  /*
    @const Page styles
  */
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      border: 0,
      background: state.isSelected ? "#77a6ff" : "#FFF",
    }),
    control: (provided) => ({
      ...provided,
      marginTop: "40px",
      border: 0,
      borderBottom: "1px solid rgb(200,200,200)",
    }),

    menuList: (base) => ({
      ...base,
      "::-webkit-scrollbar-thumb": {
        background: "#77a6ff",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#4586fd",
      },
    }),
  };

  /*
    @function Login page logic
  */
  const [Error, setError] = useState("");
  const [sucess, setSuccess] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [ivuSwitch, setIvuSwitch] = useState(true);
  const [signFooterSwitch, setSignFooterSwitch] = useState(true);
  const [transItPlateform, setTransItPlateform] = useState(true);
  const [enableATHBusiness, setEnableATHBusiness] = useState(true);
  const [print_customer_name, setPrintCustomerName] = useState(true);
  const [print_table_number, setPrintTableNumber] = useState(true);
  const [ask_customer_name, setAskCustomerName] = useState(true);
  const [ask_table_number, setAskTableNumber] = useState(true);
  const [promptSignatureonPINDebit, setPromptSignatureonPINDebit] =
    useState(true);
  const [accept_debit_cards, setAcceptDebitCard] = useState(true);
  const [accept_credit_cards, setAcceptCreditCard] = useState(true);
  const [accept_cash_cards, setAcceptCash] = useState(true);
  const [accept_ebt, setAcceptEbt] = useState(true);
  const [custom_tab, setShowCustomTab] = useState(true);
  const [item_tab, setShowItemTab] = useState(true);
  const [enable_quick_payment, setEnableQuickPayment] = useState(true);
  const [include_tax, setIncludeTax] = useState(true);

  const [logo, setLogo] = useState("");
  const [deleteLogoCheck, setDeleteLogoCheck] = useState(false);
  const [getLogo, setGetLogo] = useState("");
  const [getQrLogo, setGetQrLogo] = useState("");
  const [accessPage, setAccessPage] = useState(1);
  const [offline_push_days, setOfflinePushDays] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [cardHolderSignature, setCardHolderSignature] = React.useState("");

  const [cashDiscountAmount, setCashDiscountAmount] = useState("");
  const [enableCashDiscountProgram, setEnableCashDiscoiuntProgram] =
    useState(true);
  const [remove_fee_chk_box, setRemoveFee] = useState(true);
  const [applyFeeCreditCard, setApplyFeeCreditCard] = useState(false);
  const [applyFeeDebitCard, setApplyFeeDebitCard] = useState(false);

  const [processor, setProcessor] = useState("");
  const [processorNote, setProcessorNote] = useState("");
  const [permissionData, setPermissionData] = useState({});

  const [businessSettingVisible, setBusinessSettingVisible] = useState(false);
  const [transitSettingVisible, setTransitSettingVisible] = useState(false);
  const [cvvSettingVisible, setCVVSettingVisible] = useState(false);
  const [avsSettingVisible, setAVSSettingVisible] = useState(false);
  const [ivuSettingVisible, setIVUSettingVisible] = useState(false);
  const [receiptSettingVisible, setReceiptSettingVisible] = useState(false);
  const [batchSettingVisible, setBatchSettingVisible] = useState(false);
  const [reportSettingVisible, setReportSettingVisible] = useState(false);
  const [otherSettingVisible, setOtherSettingVisible] = useState(false);
  const [cashDiscSettingVisible, setCashDiscSettingVisible] = useState(false);
  const [athSettingVisible, setATHSettingVisible] = useState(false);
  const [sendPaymentLink, setSendPaymentLink] = useState("");

  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [allStateData, setAllStateData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectOpen, setSelectOpen] = useState(false);
  const [isFocus, setIsFocus] = useState(false);

  const [cvvHideForMerchant, setCVVHideForMerchant] = useState(false);
  const [avsHideForMerchant, setAVSHideForMerchant] = useState(false);
  // showBusinessInfoSetting

  const showBusinessInfoSetting = (e) => {
    setBusinessSettingVisible(true);
    setTransitSettingVisible(false);
    setCVVSettingVisible(false);
    setAVSSettingVisible(false);
    setIVUSettingVisible(false);
    setReceiptSettingVisible(false);
    setBatchSettingVisible(false);
    setReportSettingVisible(false);
    setOtherSettingVisible(false);
    setCashDiscSettingVisible(false);
    setATHSettingVisible(false);
  };
  const showTransitInfoSetting = (e) => {
    setBusinessSettingVisible(false);
    setTransitSettingVisible(true);
    setCVVSettingVisible(false);
    setAVSSettingVisible(false);
    setIVUSettingVisible(false);
    setReceiptSettingVisible(false);
    setBatchSettingVisible(false);
    setReportSettingVisible(false);
    setOtherSettingVisible(false);
    setCashDiscSettingVisible(false);
    setATHSettingVisible(false);
  };
  const showTCVVSetting = (e) => {
    setCVVSettingVisible(true);
    setBusinessSettingVisible(false);
    setTransitSettingVisible(false);
    setAVSSettingVisible(false);
    setIVUSettingVisible(false);
    setReceiptSettingVisible(false);
    setBatchSettingVisible(false);
    setReportSettingVisible(false);
    setOtherSettingVisible(false);
    setCashDiscSettingVisible(false);
    setATHSettingVisible(false);
  };
  const showAVSSetting = (e) => {
    setCVVSettingVisible(false);
    setBusinessSettingVisible(false);
    setTransitSettingVisible(false);
    setAVSSettingVisible(true);
    setIVUSettingVisible(false);
    setReceiptSettingVisible(false);
    setBatchSettingVisible(false);
    setReportSettingVisible(false);
    setOtherSettingVisible(false);
    setCashDiscSettingVisible(false);
    setATHSettingVisible(false);
  };

  const showIVUSetting = (e) => {
    setCVVSettingVisible(false);
    setBusinessSettingVisible(false);
    setTransitSettingVisible(false);
    setAVSSettingVisible(false);
    setIVUSettingVisible(true);
    setReceiptSettingVisible(false);
    setBatchSettingVisible(false);
    setReportSettingVisible(false);
    setOtherSettingVisible(false);
    setCashDiscSettingVisible(false);
    setATHSettingVisible(false);
  };

  const showReceiptSetting = (e) => {
    setCVVSettingVisible(false);
    setBusinessSettingVisible(false);
    setTransitSettingVisible(false);
    setAVSSettingVisible(false);
    setIVUSettingVisible(false);
    setReceiptSettingVisible(true);
    setBatchSettingVisible(false);
    setReportSettingVisible(false);
    setOtherSettingVisible(false);
    setCashDiscSettingVisible(false);
    setATHSettingVisible(false);
  };

  const showBatchSetting = (e) => {
    setCVVSettingVisible(false);
    setBusinessSettingVisible(false);
    setTransitSettingVisible(false);
    setAVSSettingVisible(false);
    setIVUSettingVisible(false);
    setReceiptSettingVisible(false);
    setBatchSettingVisible(true);
    setReportSettingVisible(false);
    setOtherSettingVisible(false);
    setCashDiscSettingVisible(false);
    setATHSettingVisible(false);
  };

  const showReportSetting = (e) => {
    setCVVSettingVisible(false);
    setBusinessSettingVisible(false);
    setTransitSettingVisible(false);
    setAVSSettingVisible(false);
    setIVUSettingVisible(false);
    setReceiptSettingVisible(false);
    setBatchSettingVisible(false);
    setReportSettingVisible(true);
    setOtherSettingVisible(false);
    setCashDiscSettingVisible(false);
    setATHSettingVisible(false);
  };

  const showOtherSetting = (e) => {
    setCVVSettingVisible(false);
    setBusinessSettingVisible(false);
    setTransitSettingVisible(false);
    setAVSSettingVisible(false);
    setIVUSettingVisible(false);
    setReceiptSettingVisible(false);
    setBatchSettingVisible(false);
    setReportSettingVisible(false);
    setOtherSettingVisible(true);
    setCashDiscSettingVisible(false);
    setATHSettingVisible(false);
  };

  const showCashDiscSetting = (e) => {
    setCVVSettingVisible(false);
    setBusinessSettingVisible(false);
    setTransitSettingVisible(false);
    setAVSSettingVisible(false);
    setIVUSettingVisible(false);
    setReceiptSettingVisible(false);
    setBatchSettingVisible(false);
    setReportSettingVisible(false);
    setOtherSettingVisible(false);
    setCashDiscSettingVisible(true);
    setATHSettingVisible(false);
  };

  const showATHSetting = (e) => {
    setCVVSettingVisible(false);
    setBusinessSettingVisible(false);
    setTransitSettingVisible(false);
    setAVSSettingVisible(false);
    setIVUSettingVisible(false);
    setReceiptSettingVisible(false);
    setBatchSettingVisible(false);
    setReportSettingVisible(false);
    setOtherSettingVisible(false);
    setCashDiscSettingVisible(false);
    setATHSettingVisible(true);
  };

  const handleEnableATHBusiness = (e) => {
    setEnableATHBusiness(e);

    if (e) {
      document.getElementById("labelForQrCodeUploadBtn").style.display =
        "block";
      document.getElementById("qrCodeImageDiv").style.display = "flex";
      document.getElementById("inputATHBusinessPath").style.display = "block";
      document.getElementById("inputATHWebhookURL").style.display = "block";
      document.getElementById("inputATHPublicToken").style.display = "block";
      document.getElementById("inputATHPrivateToken").style.display = "block";
    } else {
      document.getElementById("labelForQrCodeUploadBtn").style.display = "none";
      document.getElementById("qrCodeImageDiv").style.display = "none";
      document.getElementById("inputATHBusinessPath").style.display = "none";
      document.getElementById("inputATHWebhookURL").style.display = "none";
      document.getElementById("inputATHPublicToken").style.display = "none";
      document.getElementById("inputATHPrivateToken").style.display = "none";
    }
  };

  const handleSetCashDiscount = (e) => {
    setEnableCashDiscoiuntProgram(e);
    if (e) {
      setApplyFeeCreditCard(1);
      setApplyFeeDebitCard(1);
      document.getElementById("tfDiscount").style.display = "block";
      document.getElementById("swAllowRemoveFee").style.display = "block";
    } else {
      setApplyFeeCreditCard(0);
      setApplyFeeDebitCard(0);
      document.getElementById("tfDiscount").style.display = "none";
      document.getElementById("swAllowRemoveFee").style.display = "none";
    }
  };

  const deleteCompanyLogo = () => {
    document.getElementById("uploadLogoBtn").style.display = "block";
    document.getElementById("cancelLogoBtn").style.display = "block";
    document.getElementById("logoImage").style.display = "none";
    document.getElementById("deleteLogoBtn").style.display = "none";
    setDeleteLogoCheck(true);
  };

  const cancelCompanyLogo = () => {
    document.getElementById("uploadLogoBtn").style.display = "none";
    document.getElementById("cancelLogoBtn").style.display = "none";
    document.getElementById("logoImage").style.display = "block";
    document.getElementById("deleteLogoBtn").style.display = "block";
  };

  const deleteQrCodeImage = () => {
    document.getElementById("labelForQrCodeUploadBtn").style.display = "block";
    document.getElementById("qrCodeImage").style.display = "none";
    document.getElementById("deleteQrCodeImageBtn").style.display = "none";
  };

  const handleStateChange = (e) => {
    (localStorage.getItem("role") === "admin" &&
      (permissionData.state == "1" || adminEmail == "admin@magicpay.net")) ||
    localStorage.getItem("role") == "merchant"
      ? setState(e.value)
      : "";
    if (e.value != "Puerto Rico" || e.value != "US Virgin Islands") {
      setEnableATHBusiness(false);
    }
  };

  const handleSMSClick = (e) => {
    e.preventDefault();
    setSmsDailog(true);
  };
  const sendSMS = (e) => {
    e.preventDefault();

    const data = {};
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };
    data.ath_url = ath_webhook_url;
    data.number = mobileNumber;
    data.parent_id = localStorage.getItem("parent_id");
    data.location_id = localStorage.getItem("location_id");
    axios
      .post(window.apiUrl + "ath_url_sms", data, {
        //Get data from api
        headers: headers,
      })
      .then((response) => {
        setMobileNumber("");
        setSuccess(true);
        setShowLoader(false);
        setSuccessMsg("SMS Sent Successfully.");
        setSmsDailog(false);
      })
      .catch((error) => {
        setError(true);
        setShowLoader(false);
        setErrorMsg("Some Error Occur!");
        return error;
      });
  };

  const sendAthURLEmail = (e) => {
    e.preventDefault();

    const data = {};
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };
    data.ath_url = ath_webhook_url;
    data.email = ath_send_email;
    data.parent_id = localStorage.getItem("parent_id");
    data.location_id = localStorage.getItem("location_id");
    axios
      .post(window.apiUrl + "ath_url_email", data, {
        //Get data from api
        headers: headers,
      })
      .then((response) => {
        setAthSendEmail("");
        setEmailDailog(false);
        setSuccess(true);
        setSuccessMsg("Email Sent Successfully.");
      })
      .catch((error) => {
        setError(true);
        setErrorMsg("Some Error Occur!");
        return error;
      });
  };

  /*
   @function Popup close
  */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
    setSuccess(false);
  };
  const handleSMSClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMobileNumber("");
  };
  /*
    @get Merchant setting start
  */
  useEffect(() => {
    var permission = JSON.parse(localStorage.getItem("admin_permission"));

    var groupID = localStorage.getItem("group_id");

    if (groupID != null && groupID != "0" && groupID != "") {
      const allowGroupIdArr = groupID.split(",");
      var arr = {};
      allowGroupIdArr.forEach((value, key) => {
        arr[value] = groupMenuList[value];
      });
      setGroupMenuList(arr);
    }

    setPermissionData(permission);
    if (localStorage.getItem("role") === "admin") {
      setAdminEmail(localStorage.getItem("admin_email"));
      setShowGroupMenuList(true);
    }

    var is_location_active = localStorage.getItem("is_active");
    if (is_location_active === "0") {
      window.location.href = "/in_active";
      return;
    }

    const permissonData = JSON.parse(localStorage.getItem("permissonData"));

    if (permissonData) {
      if (
        permissonData.access_settings_menu === 0 ||
        permissonData.access_to_online_dashboard === 0
      ) {
        setAccessPage(0);
        return;
      }
    }
    const data = {};
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };
    data.parent_id = localStorage.getItem("parent_id");
    data.location_id = localStorage.getItem("location_id");
    axios
      .post(window.apiUrl + "get_setting", data, {
        //Get data from api
        headers: headers,
      })
      .then((response) => {
        // set Country and State List (#start)
        setCountryList(response.data.countryData);
        var data = JSON.parse(JSON.stringify(response.data.stateData));
        setAllStateData(data);
        setStateList(data[response.data.settingData.country]);
        setSelectedCountry(response.data.settingData.country);
        setSelectedState(response.data.settingData.state);
        // set Country and State List (#end)

        setAskCustomerName(response.data.settingData.ask_customer_name);
        setAskTableNumber(response.data.settingData.ask_table_number);
        setPrintCustomerName(response.data.settingData.print_customer_name);
        setPrintTableNumber(response.data.settingData.print_table_number);
        setEmail(response.data.settingData.email);
        setBusinessName(response.data.settingData.business_name);
        setStreetAddress(response.data.settingData.street_address);
        setCity(response.data.settingData.city);
        setZip(String(response.data.settingData.zip).padStart(5, "0"));
        setState(response.data.settingData.state);
        setPhoneNumber(response.data.settingData.phone_number);
        setTaxIdNumber(response.data.settingData.puerto_rico_number);
        setStttingData(true);
        setSesstionTimeout(response.data.settingData.session_timeout);
        setPasscode(response.data.settingData.passcode);
        setIvuSwitch(response.data.settingData.evertec_flag);
        setSignFooterLine(response.data.settingData.sign_footer_line);
        // setEnableATHBusiness(response.data.settingData.accept_ath);
        // handleEnableATHBusiness(response.data.settingData.accept_ath);
        setFooterLine(response.data.settingData.footer_line);
        setSignFooterSwitch(response.data.settingData.receipt_signature_footer);
        setBatchAutoEmail(response.data.settingData.auto_batch_email);

        setCVVHideForMerchant(
          response.data.settingData.cvv_hide_for_merchant_button
        );
        setAVSHideForMerchant(
          response.data.settingData.avs_hide_for_merchant_button
        );

        var batchAutoCloseTime =
          response.data.settingData.auto_batch_close_time.substring(0, 5);

        setAutoBatchCloseTime(batchAutoCloseTime);
        setWebsite(response.data.settingData.website);
        setMerchantID(response.data.settingData.merchant_id);
        setSendIvuData(response.data.settingData.send_ivu_data);
        setMerchantCatCode(response.data.settingData.merchant_cat_code);
        setContactName(response.data.settingData.name);
        setGetLogo(response.data.settingData.logo);
        setOfflinePushDays(response.data.settingData.offline_push_days);
        setGetQrLogo(response.data.settingData.ath_qr_code_img);

        var EnableAthValue =
          response.data.settingData.accept_ath == 1 ? true : false;
        setEnableATHBusiness(EnableAthValue);
        handleEnableATHBusiness(EnableAthValue);

        if (response.data.settingData.ath_qr_code_img) {
          document.getElementById("labelForQrCodeUploadBtn").style.display =
            "none";
          document.getElementById("qrCodeImage").style.display = "block";
          document.getElementById("deleteQrCodeImageBtn").style.display =
            "block";
        }
        setATHPath(response.data.settingData.ath_business_path);
        setATHWebhookURL(
          "https://api.magicpaypos.com/api/ath/" +
            response.data.settingData.ath_business_path
        );
        setATHPublicToken(response.data.settingData.ath_public_token);
        setATHPrivateToken(response.data.settingData.ath_private_token);

        // other setting switch value set
        setAcceptDebitCard(
          response.data.settingData.accept_debit_cards == 1 ? true : false
        );
        setAcceptCreditCard(
          response.data.settingData.accept_credit_cards == 1 ? true : false
        );
        setAcceptCash(
          response.data.settingData.accept_cash_cards == 1 ? true : false
        );
        setAcceptEbt(response.data.settingData.accept_ebt == 1 ? true : false);
        setShowCustomTab(
          response.data.settingData.custom_tab == 1 ? true : false
        );
        setShowItemTab(response.data.settingData.item_tab == 1 ? true : false);
        setEnableQuickPayment(
          response.data.settingData.enable_quick_payment == 1 ? true : false
        );
        setIncludeTax(
          response.data.settingData.include_tax == 1 ? true : false
        );
        setRemoveFee(
          response.data.settingData.remove_fee_chk_box == 1 ? true : false
        );
        setEnableCashDiscoiuntProgram(
          response.data.settingData.cash_discount_program == 1 ? true : false
        );
        setPromptSignatureonPINDebit(
          response.data.settingData.prompt_sign_on_pin_debit == 1 ? true : false
        );
        setCardHolderSignature(response.data.settingData.card_holder_sign);
        setCashDiscountAmount(
          response.data.settingData.cash_discount_percentage
        );
        setApplyFeeCreditCard(
          response.data.settingData.applay_non_cash_fee_to_credit
        );
        setApplyFeeDebitCard(
          response.data.settingData.applay_non_cash_fee_to_debit
        );
        setGroupId(response.data.settingData.group_id);
        //Transit Setting Value set
        setTransitMerchantId(response.data.settingData.transit_merchant_id);
        setTransitDeviceId(response.data.settingData.transit_device_id);
        setTransitTSEPDeviceId(
          response.data.settingData.transit_tsep_device_id
        );
        setTransitUserId(response.data.settingData.transit_user_id);
        setTransitDeveloperId(response.data.settingData.transit_developer_id);
        
        setTransItPlateform(
          response.data.settingData.transit_plateform == "1" ? true : false
        );

        setTransitChkUserId(response.data.settingData.transit_user_id);
        setTransitChkMerchantId(response.data.settingData.transit_merchant_id);
        // setTransitTransactionKey(response.data.settingData.transit_transaction_key);

        setProcessor(response.data.settingData.processor);

        if (response.data.settingData.processor === "TSYS TransIT") {
          document.getElementById("transitInfo").style.display = "block";
          setProcessorNote(
            <span>
              <b>Note: </b>Please check below TransIT Information properly for
              API calls
            </span>
          );
          var cvvRuleData = JSON.parse(response.data.settingData.vm_void_rules);
          setCvvCodeN(cvvRuleData.cvv_code_n == "1" ? true : false);
          setCvvCodeP(cvvRuleData.cvv_code_p == "1" ? true : false);
          setCvvCodeS(cvvRuleData.cvv_code_s == "1" ? true : false);
          setCvvCodeU(cvvRuleData.cvv_code_u == "1" ? true : false);
          setAVSCode0(cvvRuleData.avs_code_0 == "1" ? true : false);
          setAVSCode1(cvvRuleData.avs_code_1 == "1" ? true : false);
          setAVSCode2(cvvRuleData.avs_code_2 == "1" ? true : false);
          setAVSCode3(cvvRuleData.avs_code_3 == "1" ? true : false);
          setAVSCode4(cvvRuleData.avs_code_4 == "1" ? true : false);
          setAVSCode5(cvvRuleData.avs_code_5 == "1" ? true : false);
          setAVSCode6(cvvRuleData.avs_code_6 == "1" ? true : false);
          setAVSCode7(cvvRuleData.avs_code_7 == "1" ? true : false);
          setAVSCode8(cvvRuleData.avs_code_8 == "1" ? true : false);
          setAVSCodeA(cvvRuleData.avs_code_A == "1" ? true : false);
          setAVSCodeB(cvvRuleData.avs_code_B == "1" ? true : false);
          setAVSCodeC(cvvRuleData.avs_code_C == "1" ? true : false);
          setAVSCodeD(cvvRuleData.avs_code_D == "1" ? true : false);
          setAVSCodeF(cvvRuleData.avs_code_F == "1" ? true : false);
          setAVSCodeG(cvvRuleData.avs_code_G == "1" ? true : false);
          setAVSCodeI(cvvRuleData.avs_code_I == "1" ? true : false);
          setAVSCodeM(cvvRuleData.avs_code_M == "1" ? true : false);
          setAVSCodeN(cvvRuleData.avs_code_N == "1" ? true : false);
          setAVSCodeP(cvvRuleData.avs_code_P == "1" ? true : false);
          setAVSCodeR(cvvRuleData.avs_code_R == "1" ? true : false);
          setAVSCodeS(cvvRuleData.avs_code_S == "1" ? true : false);
          setAVSCodeU(cvvRuleData.avs_code_U == "1" ? true : false);
          setAVSCodeW(cvvRuleData.avs_code_W == "1" ? true : false);
          setAVSCodeX(cvvRuleData.avs_code_X == "1" ? true : false);
          setAVSCodeY(cvvRuleData.avs_code_Y == "1" ? true : false);
          setAVSCodeZ(cvvRuleData.avs_code_Z == "1" ? true : false);
        } else {
          document.getElementById("transitInfo").style.display = "none";
          setProcessorNote("");
        }

        if (response.data.settingData.cash_discount_program == 0) {
          document.getElementById("tfDiscount").style.display = "none";
          document.getElementById("swAllowRemoveFee").style.display = "none";
        }

        let report_start_time =
          response.data.settingData.report_start_time.split(":");
        let t = new Date();
        t.setHours(report_start_time[0]);
        t.setMinutes(report_start_time[1]);
        t.setSeconds(0);
        setStartDate(t);
      })
      .catch((error) => {
        return error;
      });
  }, []);

  const checkBothOff = (e) => {
    // alert(custom_tab);
    if (custom_tab == false && item_tab == true) {
      alert("cant off both switch");
      setShowItemTab(true);
    } else if (custom_tab == true && item_tab == false) {
      alert("cant off both switch");
      setShowCustomTab(true);
    }
  };

  const setEmailErrorMsg = (e) => {
    setError(true);
    setErrorMsg("Please enter email");
  };
  const setMobileErrorMsg = (e) => {
    setError(true);
    setErrorMsg("Please enter mobile number");
  };

  /*
  @function For validate tip value
*/
  const validateCashDiscountValue = (e) => {
    var val = e.target.value;
    if (val.length < 6) {
      var per = val.replace(".", "");
      per = per / 100;
      if (!isNaN(per)) {
        setCashDiscountAmount(parseFloat(per).toFixed(2));
      }
    }
  };
  const voidCodeRuleSubmit = () => {
    event.preventDefault();
    const voidRuleData = {
      cvv_code_n: cvvCodeN === true ? 1 : 0,
      cvv_code_p: cvvCodeP === true ? 1 : 0,
      cvv_code_s: cvvCodeS === true ? 1 : 0,
      cvv_code_u: cvvCodeU === true ? 1 : 0,
      avs_code_0: avsCode0 === true ? 1 : 0,
      avs_code_1: avsCode1 === true ? 1 : 0,
      avs_code_2: avsCode2 === true ? 1 : 0,
      avs_code_3: avsCode3 === true ? 1 : 0,
      avs_code_4: avsCode4 === true ? 1 : 0,
      avs_code_5: avsCode5 === true ? 1 : 0,
      avs_code_6: avsCode6 === true ? 1 : 0,
      avs_code_7: avsCode7 === true ? 1 : 0,
      avs_code_8: avsCode8 === true ? 1 : 0,
      avs_code_A: avsCodeA === true ? 1 : 0,
      avs_code_B: avsCodeB === true ? 1 : 0,
      avs_code_C: avsCodeC === true ? 1 : 0,
      avs_code_D: avsCodeD === true ? 1 : 0,
      avs_code_F: avsCodeF === true ? 1 : 0,
      avs_code_G: avsCodeG === true ? 1 : 0,
      avs_code_I: avsCodeI === true ? 1 : 0,
      avs_code_M: avsCodeM === true ? 1 : 0,
      avs_code_N: avsCodeN === true ? 1 : 0,
      avs_code_P: avsCodeP === true ? 1 : 0,
      avs_code_R: avsCodeR === true ? 1 : 0,
      avs_code_S: avsCodeS === true ? 1 : 0,
      avs_code_U: avsCodeU === true ? 1 : 0,
      avs_code_W: avsCodeW === true ? 1 : 0,
      avs_code_X: avsCodeX === true ? 1 : 0,
      avs_code_Y: avsCodeY === true ? 1 : 0,
      avs_code_Z: avsCodeZ === true ? 1 : 0,
    };
    const data = { vm_void_rules: voidRuleData };
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };
    data.parent_id = localStorage.getItem("parent_id");
    data.location_id = localStorage.getItem("location_id");
    axios
      .post(window.apiUrl + "save_setting", data, {
        headers: headers,
      })
      .then((response) => {
        if (response) {
          if (response.data.error) {
            //Check data updated successfully or any error generate
            setErrorMsg(response.data.error);
            setError(true);
          } else {
            setSuccessMsg("Setting Updated Successfully!");
            setSuccess(true);
          }
        }
      })
      .catch((error) => {
        setErrorMsg("Fail To Update Settings.");
        setError(true);
        return error;
      });
  };

  const handleMexico = (val) =>
    {    
      setCity("");
      setZip("");
    }

  const handleMexicoZip = (val) =>
    {
      setZipcodeLoding(true);
        if(val.length == 5)
        {
          setZip(val);
        }else{
          val=zip;
        }
        var data = { zip: val};
        data.admin_token = localStorage.getItem("merchantToken");
        data.parent_id = localStorage.getItem('parent_id');
        data.location_id = localStorage.getItem('location_id');
        const headers = {
          'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
        }
        axios.post(window.apiUrl + 'get_mexico_detail_using_zip', data, {
          headers: headers
        }).then(response => {
          setSelectedState(response.data.state);
          setCity(response.data.city);      
          setZipcodeLoding(false);
          if(!response.data){
            setCity("");
            // setZip("");
          }
        }).catch(error => {
          setError(true);
          setErrorMsg("Some Error to  get detail using!");
          setZipcodeLoding(false);
          return error;
        });      
    }

  /*
    @get Merchant setting end
  */
  if (accessPage === 0) {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>Your Settings</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={12} md={4}></GridItem>
                <center>
                  <h3>
                    <div>You do not have permission to access this page.</div>{" "}
                    <div>Please contact an administrator.</div>
                    <div>Thank you.</div>
                  </h3>
                </center>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
  var purtoNumberHtml = "";
  if (state === "Puerto Rico") {
    //Check selected state was Puerto Rico or not
    purtoNumberHtml = (
      <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          id="settingSection-IVUControl"
          style={{ display: ivuSettingVisible ? "block" : "none" }}
        >
          <Card>
            {/**
             *Form for IVU Control settings
             */}
            <form onSubmit={handleIVUSubmit}>
              <CardHeader className="cardheader" color="warning">
                <h4 className={classes.cardTitleWhite}>IVU Control</h4>
              </CardHeader>

              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    Do you want to generate IVU Control Number?
                    <Switch
                      checked={ivuSwitch}
                      onChange={(e) => {
                        setIvuSwitch(e.target.checked);
                      }}
                      id="ivuswitch"
                    />
                  </GridItem>
                </GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Tax Id Number"
                    id="taxIdnumber"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={Helper.formatIVUControlNumber(taxIdNumber)}
                    onChange={(e) => setTaxIdNumber(e.target.value)}
                  />
                </GridItem>
                <div style={{ display: ivuSwitch ? "block" : "none" }}>
                  <GridItem xs={12} sm={12} md={12}>
                    Send IVU Data?
                    <Switch
                      checked={sendIvuData}
                      onChange={(e) => {
                        setSendIvuData(e.target.checked);
                      }}
                      id="sendIvuSwitch"
                    />
                  </GridItem>
                </div>
              </CardBody>

              <CardFooter>
                <Button id="btnGreenAdd" type="submit" color="warning">
                  Save
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  if (!settingData) {
    //Check if setting data not come from api than display loading progress bar
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>Your Settings</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={12} md={4}></GridItem>
                <center>
                  <h3>Loading...</h3>
                  <CircularProgress id="loginProgress"></CircularProgress>
                </center>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
  return (
    <div>
      {/* 
        Display error message
      */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={Error}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>

      {/* 
        Display success message
      */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={sucess}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          {successMsg}
        </Alert>
      </Snackbar>
      {/* SMS dailog start */}
      <Dialog
        open={smsDailog}
        keepMounted
        onClose={handleSMSClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Please Enter Mobile Number"}
        </DialogTitle>
        {showLoader == false ? (
          <DialogContent style={{ marginTop: "-40px" }}>
            <CustomInput
              labelText="Mobile Number"
              id="m_id"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{ maxLength: 12 }}
              value={mobileNumber}
              onChange={(e) => {
                setMobileNumber(e.target.value);
              }}
            />
          </DialogContent>
        ) : (
          <DialogContent style={{ textAlign: "center" }}>
            <CircularProgress id="loginProgress"></CircularProgress>
          </DialogContent>
        )}

        <DialogActions>
          <Button
            id="btnGreyCancel"
            onClick={() => {
              setSmsDailog(false);
            }}
          >
            Cancel
          </Button>
          {/* sendSMS */}
          <Button
            id="btnSMSSend"
            onClick={(e) => {
              mobileNumber === "" ? setMobileErrorMsg() : sendSMS(e),
                setShowLoader(true);
            }}
            color="secondery"
            style={{ backgroundColor: "#ffa726", color: "#FFFFFF" }}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
      {/* SMS dialog start */}
      {/* Email dialog start */}
      <Dialog
        open={emailDailog}
        keepMounted
        onClose={(e) => {
          setEmailDailog(false);
          setAthSendEmail("");
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Please Enter Email Address"}
        </DialogTitle>
        <DialogContent style={{ marginTop: "-40px" }}>
          <CustomInput
            labelText="Email"
            id="m_id"
            formControlProps={{
              fullWidth: true,
            }}
            value={ath_send_email}
            onChange={(e) => {
              setAthSendEmail(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            id="btnGreyCancel"
            onClick={() => {
              setEmailDailog(false);
            }}
          >
            Cancel
          </Button>
          <Button
            id="btnEmailSend"
            onClick={(e) => {
              ath_send_email === "" ? setEmailErrorMsg() : sendAthURLEmail(e);
            }}
            color="secondery"
            style={{ backgroundColor: "#ffa726", color: "#FFFFFF" }}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
      {/* Email dialog end */}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>About Your Business</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ marginTop: "5px !important" }}
                >
                  <span
                    id="setttingLinks"
                    data-id="BusinessSetting"
                    onClick={() => {
                      showBusinessInfoSetting();
                    }}
                  >
                    Business Information
                  </span>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ marginTop: "5px !important" }}
                >
                  <span
                    id="setttingLinks"
                    data-id="BatchSetting"
                    onClick={() => {
                      showBatchSetting();
                    }}
                  >
                    Batch Settlement
                  </span>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  style={{
                    marginTop: "5px !important",
                    display: processor == "TSYS TransIT" ? "block" : "none",
                  }}
                >
                  <span
                    id="setttingLinks"
                    data-id="TransitInfo"
                    onClick={() => {
                      showTransitInfoSetting();
                    }}
                  >
                    TSYS TransIT
                  </span>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>Business Operations</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  style={{
                    marginTop: "5px !important",
                    display:
                      processor == "TSYS TransIT" && cvvHideForMerchant == 0
                        ? "block"
                        : "none",
                  }}
                >
                  <span
                    id="setttingLinks"
                    data-id="CVVSetting"
                    onClick={() => {
                      showTCVVSetting();
                    }}
                  >
                    Card ID Verification
                  </span>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  style={{
                    marginTop: "5px !important",
                    display:
                      processor == "TSYS TransIT" && avsHideForMerchant == 0
                        ? "block"
                        : "none",
                  }}
                >
                  <span
                    id="setttingLinks"
                    data-id="AVSSetting"
                    onClick={() => {
                      showAVSSetting();
                    }}
                  >
                    Address Verification
                  </span>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ marginTop: "5px !important" }}
                >
                  <span
                    id="setttingLinks"
                    data-id="ReceiptLayout"
                    onClick={() => {
                      showReceiptSetting();
                    }}
                  >
                    Receipt Layout
                  </span>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ marginTop: "5px !important" }}
                >
                  <span
                    id="setttingLinks"
                    data-id="ReportSetting"
                    onClick={() => {
                      showReportSetting();
                    }}
                  >
                    Report Settings
                  </span>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ marginTop: "5px !important" }}
                >
                  <span
                    id="setttingLinks"
                    data-id="OtherSetting"
                    onClick={() => {
                      showOtherSetting();
                    }}
                  >
                    Other Settings
                  </span>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}                  
                  style={{
                    marginTop: "5px !important",
                    display:
                     selectedState == "U.S. Virgin Islands"
                        ? "none"
                        : "block",
                  }}
                >
                  <span
                    id="setttingLinks"
                    data-id="CashDiscProgram"
                    onClick={() => {
                      showCashDiscSetting();
                    }}
                  >
                    Cash Discount Program
                  </span>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  style={{
                    marginTop: "5px !important",
                    display:
                    selectedState == "Puerto Rico" || selectedState == "U.S. Virgin Islands"
                        ? "block"
                        : "none",
                  }}
                >
                  <span
                    id="setttingLinks"
                    data-id="ATHBusinessSetting"
                    onClick={() => {
                      showATHSetting();
                    }}
                  >
                    ATH Business
                  </span>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  style={{
                    marginTop: "5px !important",
                    display: selectedState == "Puerto Rico" ? "block" : "none",
                  }}
                >
                  <span
                    id="setttingLinks"
                    data-id="IVUControl"
                    onClick={() => {
                      showIVUSetting();
                    }}
                  >
                    IVU Control
                  </span>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          id="settingSection-BusinessSetting"
          style={{ display: businessSettingVisible ? "block" : "none" }}
        >
          <Card>
            {/*
             * Form business information settings
             */}
            <form onSubmit={handleSubmit}>
              <CardHeader className="cardheader" color="warning">
                <h4 className={classes.cardTitleWhite}>Business Settings</h4>
              </CardHeader>

              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Merchant ID"
                      id="m_id"
                      formControlProps={{
                        fullWidth: true,
                        disabled:
                          localStorage.getItem("role") === "admin" &&
                          (permissionData.mid == "1" ||
                            adminEmail == "admin@magicpay.net")
                            ? false
                            : true,
                      }}
                      inputProps={{ maxLength: 24 }}
                      value={merchant_id}
                      onChange={(e) => {
                        localStorage.getItem("role") === "admin" &&
                        (permissionData.mid == "1" ||
                          adminEmail == "admin@magicpay.net")
                          ? setMerchantID(e.target.value)
                          : "";
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Merchant Category Code"
                      id="m_cat_code"
                      formControlProps={{
                        fullWidth: true,
                        disabled:
                          localStorage.getItem("role") === "admin" &&
                          (permissionData.mcc == "1" ||
                            adminEmail == "admin@magicpay.net")
                            ? false
                            : true,
                      }}
                      inputProps={{ maxLength: 4 }}
                      value={merchant_cat_code}
                      onChange={(e) => {
                        localStorage.getItem("role") === "admin" &&
                        (permissionData.mcc == "1" ||
                          adminEmail == "admin@magicpay.net")
                          ? setMerchantCatCode(e.target.value)
                          : "";
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Business Name"
                      id="businessname"
                      formControlProps={{
                        fullWidth: true,
                        disabled:
                          (localStorage.getItem("role") === "admin" &&
                            (permissionData.bussiness_name == "1" ||
                              adminEmail == "admin@magicpay.net")) ||
                          localStorage.getItem("role") == "merchant"
                            ? false
                            : true,
                      }}
                      value={businessName}
                      onChange={(e) => {
                        (localStorage.getItem("role") === "admin" &&
                          (permissionData.bussiness_name == "1" ||
                            adminEmail == "admin@magicpay.net")) ||
                        localStorage.getItem("role") == "merchant"
                          ? e.target.value.search('"') != -1
                            ? console.log(e.target.value)
                            : setBusinessName(
                                e.target.value.replace(/[^a-z1-9!,. ]/gi, "")
                              )
                          : "";
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Street Address"
                      id="streetaddress"
                      formControlProps={{
                        fullWidth: true,
                        disabled:
                          (localStorage.getItem("role") === "admin" &&
                            (permissionData.street_address == "1" ||
                              adminEmail == "admin@magicpay.net")) ||
                          localStorage.getItem("role") == "merchant"
                            ? false
                            : true,
                      }}
                      value={streetAddress}
                      onChange={(e) =>
                        (localStorage.getItem("role") === "admin" &&
                          (permissionData.street_address == "1" ||
                            adminEmail == "admin@magicpay.net")) ||
                        localStorage.getItem("role") == "merchant"
                          ? setStreetAddress(e.target.value)
                          : ""
                      }
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="City"
                      id="city"
                      formControlProps={{
                        fullWidth: true,
                        disabled:
                          (localStorage.getItem("role") === "admin" &&
                            (permissionData.city == "1" ||
                              adminEmail == "admin@magicpay.net")) ||
                          localStorage.getItem("role") == "merchant"
                            ? false
                            : true,
                      }}
                      value={city}
                      onChange={(e) =>
                        (localStorage.getItem("role") === "admin" &&
                          (permissionData.city == "1" ||
                            adminEmail == "admin@magicpay.net")) ||
                        localStorage.getItem("role") == "merchant"
                          ? setCity(e.target.value)
                          : ""
                      }
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer styles={{ marginTop: 50 }}>
                  <GridItem xs={12} sm={12} md={4}>
                    <FormControl style={{ width: "100%", marginTop: "20px" }}>
                      <InputLabel id="demo-simple-select-helper-label">
                        Country
                      </InputLabel>
                      <SelectMui
                        disabled={
                          (localStorage.getItem("role") === "admin" &&
                            (permissionData.state == "1" ||
                              adminEmail == "admin@magicpay.net")) ||
                          localStorage.getItem("role") == "merchant"
                            ? false
                            : true
                        }
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={selectedCountry}
                        label="Country"
                        onChange={(e) => {
                          setSelectedState("");
                          setSelectedCountry(e.target.value);
                          allStateData[e.target.value] === undefined
                            ? setStateList([])
                            : setStateList(allStateData[e.target.value]);
                            (e.target.value == "Mexico")?handleMexico(e.target.value):""
                        }} //
                      >
                        {Object.entries(countryList).map(([key, value]) => (
                          <MenuItem key={key} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </SelectMui>
                    </FormControl>
                  </GridItem>

                  

                  {(countryList[selectedCountry] == "Mexico") ? <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Zip Code"
                        inputProps={{ max: 5, type: "number" }}
                        id="zipcode"
                        formControlProps={{
                          fullWidth: true,
                          disabled:
                            (localStorage.getItem("role") === "admin" &&
                              (permissionData.zipcode == "1" ||
                                adminEmail == "admin@magicpay.net")) ||
                            localStorage.getItem("role") == "merchant"
                              ? false
                              : true,
                        }}
                        value={String(zip)}
                        onChange={(e) =>
                          (localStorage.getItem("role") === "admin" &&
                            (permissionData.zipcode == "1" ||
                              adminEmail == "admin@magicpay.net")) ||
                          localStorage.getItem("role") == "merchant"
                            ? e.target.value.length >= 5
                              ? handleMexicoZip(e.target.value)
                              : setZip(e.target.value)
                            : ""
                        }
                      />
                    </GridItem> : <GridItem xs={12} sm={12} md={4}>
                      <FormControl
                        style={{
                          width: "100%",
                          marginTop: "20px",
                          display:
                            selectedCountry != undefined &&
                            (selectedCountry == "United States" ||
                              selectedCountry == "Mexico" ||
                              selectedCountry == "Canada")
                              ? "flex"
                              : "none",
                        }}
                      >
                        <InputLabel id="demo-simple-select-helper-label">
                          {selectedCountry == "Canada" ? "Province" : "State"}
                        </InputLabel>
                        {selectedCountry == "United States" ||
                        selectedCountry == "Mexico" ||
                        selectedCountry == "Canada" ? (
                          <SelectMui
                            disabled={
                              (localStorage.getItem("role") === "admin" &&
                                (permissionData.state == "1" ||
                                  adminEmail == "admin@magicpay.net")) ||
                              localStorage.getItem("role") == "merchant"
                                ? false
                                : true
                            }
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            autoFocus={isFocus}
                            value={selectedState}
                            label={
                              selectedCountry == "Canada" ? "Province" : "State"
                            }
                            open={selectOpen}
                            onClose={() => setSelectOpen(false)}
                            onOpen={() => {
                              if (!open) {
                                setSelectOpen(true);
                              } else {
                                setSelectOpen(false);
                              }
                            }}
                            onClick={() => {
                              if (selectOpen) {
                                setSelectOpen(false);
                              } else {
                                setSelectOpen(true);
                              }
                            }}
                            onChange={(e) => {
                              setSelectedState(e.target.value);
                              // e.target.focus();
                              setIsFocus(true);
                              console.log(e.target.value);
                              e.stopPropagation();
                            }} //
                          >
                            {Object.entries(stateList).map(([key, value]) => (
                              <MenuItem key={value} value={value}>
                                {value}
                              </MenuItem>
                            ))}
                          </SelectMui>
                        ) : (
                          ""
                        )}
                      </FormControl>

                      <FormControl
                        style={{
                          width: "100%",
                          marginTop: "18px",
                          display:
                            selectedCountry == "United States" ||
                            selectedCountry == "Mexico" ||
                            selectedCountry == "Canada" ||
                            selectedCountry == "Israel"
                              ? "none"
                              : "flex",
                        }}
                      >
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          label="State"
                          value={selectedState}
                          onChange={(e) => {
                            setSelectedState(e.target.value);
                          }}
                        />
                      </FormControl>
                    </GridItem>}

                    {(countryList[selectedCountry] == "Mexico") ? (
                    <GridItem xs={12} sm={12} md={4}>
                      <FormControl
                        style={{
                          width: "100%",
                          marginTop: "20px",
                          display:
                            selectedCountry != undefined &&
                            (selectedCountry == "United States" ||
                              selectedCountry == "Mexico" ||
                              selectedCountry == "Canada")
                              ? "flex"
                              : "none",
                        }}
                      >
                        <InputLabel id="demo-simple-select-helper-label">
                          {selectedCountry == "Canada" ? "Province" : "State"}
                        </InputLabel>
                        {selectedCountry == "United States" ||
                        selectedCountry == "Mexico" ||
                        selectedCountry == "Canada" ? (
                          <SelectMui
                            disabled={
                              (localStorage.getItem("role") === "admin" &&
                                (permissionData.state == "1" ||
                                  adminEmail == "admin@magicpay.net")) ||
                              localStorage.getItem("role") == "merchant"
                                ? false
                                : true
                            }
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            autoFocus={isFocus}
                            value={selectedState}
                            label={
                              selectedCountry == "Canada" ? "Province" : "State"
                            }
                            open={selectOpen}
                            onClose={() => setSelectOpen(false)}
                            onOpen={() => {
                              if (!open) {
                                setSelectOpen(true);
                              } else {
                                setSelectOpen(false);
                              }
                            }}
                            onClick={() => {
                              if (selectOpen) {
                                setSelectOpen(false);
                              } else {
                                setSelectOpen(true);
                              }
                            }}
                            onChange={(e) => {
                              setSelectedState(e.target.value);
                              // e.target.focus();
                              setIsFocus(true);
                              console.log(e.target.value);
                              e.stopPropagation();
                            }} //
                          >
                            {Object.entries(stateList).map(([key, value]) => (
                              <MenuItem key={value} value={value}>
                                {value}
                              </MenuItem>
                            ))}
                          </SelectMui>
                        ) : (
                          ""
                        )}
                      </FormControl>

                      <FormControl
                        style={{
                          width: "100%",
                          marginTop: "18px",
                          display:
                            selectedCountry == "United States" ||
                            selectedCountry == "Mexico" ||
                            selectedCountry == "Canada" ||
                            selectedCountry == "Israel"
                              ? "none"
                              : "flex",
                        }}
                      >
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          label="State"
                          value={selectedState}
                          onChange={(e) => {
                            setSelectedState(e.target.value);
                          }}
                        />
                      </FormControl>
                    </GridItem>
                  ) : (
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Zip Code"
                        inputProps={{ max: 5, type: "number" }}
                        id="zipcode"
                        formControlProps={{
                          fullWidth: true,
                          disabled:
                            (localStorage.getItem("role") === "admin" &&
                              (permissionData.zipcode == "1" ||
                                adminEmail == "admin@magicpay.net")) ||
                            localStorage.getItem("role") == "merchant"
                              ? false
                              : true,
                        }}
                        value={String(zip)}
                        onChange={(e) =>
                          (localStorage.getItem("role") === "admin" &&
                            (permissionData.zipcode == "1" ||
                              adminEmail == "admin@magicpay.net")) ||
                          localStorage.getItem("role") == "merchant"
                            ? e.target.value.length > 5
                              ? zip
                              : setZip(e.target.value)
                            : ""
                        }
                      />
                    </GridItem>
                  )}


                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="offline push days"
                      formControlProps={{
                        fullWidth: true,
                        disabled:
                          (localStorage.getItem("role") === "admin" &&
                            (permissionData.push_data == "1" ||
                              adminEmail == "admin@magicpay.net")) ||
                          localStorage.getItem("role") == "merchant"
                            ? false
                            : true,
                      }}
                      value={offline_push_days}
                      onChange={(e) =>
                        (localStorage.getItem("role") === "admin" &&
                          (permissionData.push_data == "1" ||
                            adminEmail == "admin@magicpay.net")) ||
                        localStorage.getItem("role") == "merchant"
                          ? setOfflinePushDays(e.target.value)
                          : ""
                      }
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Phone Number"
                      id="phonenumber"
                      formControlProps={{
                        fullWidth: true,
                        disabled:
                          (localStorage.getItem("role") === "admin" &&
                            (permissionData.phone_number == "1" ||
                              adminEmail == "admin@magicpay.net")) ||
                          localStorage.getItem("role") == "merchant"
                            ? false
                            : true,
                      }}
                      value={Helper.formatPhoneNumber(phoneNumber)}
                      onChange={(e) =>
                        (localStorage.getItem("role") === "admin" &&
                          (permissionData.phone_number == "1" ||
                            adminEmail == "admin@magicpay.net")) ||
                        localStorage.getItem("role") == "merchant"
                          ? setPhoneNumber(
                              e.target.value.replace(/[^\w\s]/gi, "")
                            )
                          : ""
                      }
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Email Address"
                      id="filled-disabled"
                      formControlProps={{
                        fullWidth: true,
                        disabled:
                          localStorage.getItem("role") === "admin" &&
                          (permissionData.email == "1" ||
                            adminEmail == "admin@magicpay.net")
                            ? false
                            : true,
                      }}
                      value={email}
                      variant="filled"
                      onChange={(e) => {
                        localStorage.getItem("role") === "admin" &&
                        (permissionData.email == "1" ||
                          adminEmail == "admin@magicpay.net")
                          ? setEmail(e.target.value)
                          : "";
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Session Timeout (in Min)"
                      id="session_timeOut"
                      formControlProps={{
                        fullWidth: true,
                        disabled:
                          (localStorage.getItem("role") === "admin" &&
                            (permissionData.session_timeout == "1" ||
                              adminEmail == "admin@magicpay.net")) ||
                          localStorage.getItem("role") == "merchant"
                            ? false
                            : true,
                      }}
                      value={sessionTimeout}
                      onChange={(e) =>
                        (localStorage.getItem("role") === "admin" &&
                          (permissionData.session_timeout == "1" ||
                            adminEmail == "admin@magicpay.net")) ||
                        localStorage.getItem("role") == "merchant"
                          ? setSesstionTimeout(e.target.value)
                          : ""
                      }
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Passcode"
                      inputProps={{ max: 4, type: "number" }}
                      id="passcode"
                      formControlProps={{
                        fullWidth: true,
                        disabled:
                          (localStorage.getItem("role") === "admin" &&
                            (permissionData.passcode == "1" ||
                              adminEmail == "admin@magicpay.net")) ||
                          localStorage.getItem("role") == "merchant"
                            ? false
                            : true,
                      }}
                      value={String(passcode)}
                      onChange={(e) =>
                        (localStorage.getItem("role") === "admin" &&
                          (permissionData.passcode == "1" ||
                            adminEmail == "admin@magicpay.net")) ||
                        localStorage.getItem("role") == "merchant"
                          ? e.target.value.length > 4
                            ? passcode
                            : setPasscode(e.target.value)
                          : ""
                      }
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Website"
                      id="web"
                      formControlProps={{
                        fullWidth: true,
                        disabled:
                          (localStorage.getItem("role") === "admin" &&
                            (permissionData.passcode == "1" ||
                              adminEmail == "admin@magicpay.net")) ||
                          localStorage.getItem("role") == "merchant"
                            ? false
                            : true,
                      }}
                      value={website == null ? "" : website}
                      onChange={(e) =>
                        (localStorage.getItem("role") === "admin" &&
                          (permissionData.website == "1" ||
                            adminEmail == "admin@magicpay.net")) ||
                        localStorage.getItem("role") == "merchant"
                          ? setWebsite(e.target.value)
                          : ""
                      }
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Contact Name"
                      id="filled-disabled"
                      formControlProps={{
                        fullWidth: true,
                        disabled:
                          localStorage.getItem("role") === "admin" &&
                          (permissionData.contact_name == "1" ||
                            adminEmail == "admin@magicpay.net")
                            ? false
                            : true,
                      }}
                      value={contactName}
                      variant="filled"
                      onChange={(e) => {
                        localStorage.getItem("role") === "admin" &&
                        (permissionData.contact_name == "1" ||
                          adminEmail == "admin@magicpay.net")
                          ? setContactName(e.target.value)
                          : "";
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControl
                      className={classes.margintopid}
                      id="aaaaaa"
                      disabled={
                        localStorage.getItem("role") === "admin" &&
                        (permissionData.processor == "1" ||
                          adminEmail == "admin@magicpay.net")
                          ? false
                          : true
                      }
                      sx={{ width: "100%" }}
                    >
                      <InputLabel id="demo-simple-select-helper-label">
                        Processor
                      </InputLabel>
                      <SelectMui
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={processor}
                        // disabled = {(localStorage.getItem('role') === "admin") ? true  : false }
                        label="Processor"
                        onChange={(e) => {
                          localStorage.getItem("role") === "admin" &&
                          (permissionData.processor == "1" ||
                            adminEmail == "admin@magicpay.net")
                            ? showTransitField(e)
                            : "";
                        }} //
                      >
                        <MenuItem value={""}></MenuItem>
                        <MenuItem value={"First Data Omaha"}>
                          First Data Omaha{" "}
                        </MenuItem>
                        <MenuItem value={"TSYS Sierra"}>TSYS Sierra</MenuItem>
                        <MenuItem value={"TSYS TransIT"}>TSYS TransIT</MenuItem>
                      </SelectMui>
                      <div>{processorNote}</div>
                    </FormControl>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <div
                      id="group_select"
                      style={{ display: showGroupMenuList ? "block" : "none" }}
                    >
                      <FormControl
                        className={classes.margintopid}
                        disabled={
                          localStorage.getItem("role") === "admin" &&
                          (permissionData.change_merchant_group == "1" ||
                            adminEmail == "admin@magicpay.net")
                            ? false
                            : true
                        }
                        sx={{ width: "100%" }}
                      >
                        <InputLabel id="demo-simple-select-helper-label">
                          Group
                        </InputLabel>
                        <SelectMui
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          value={groupId}
                          label="Group"
                          onChange={(e) => {
                            localStorage.getItem("role") === "admin" &&
                            (permissionData.change_merchant_group == "1" ||
                              adminEmail == "admin@magicpay.net")
                              ? setGroupId(e.target.value)
                              : "";
                          }}
                        >
                          {Object.entries(groupMenuList).map(([key, value]) => (
                            <MenuItem key={key} value={key}>
                              {value}
                            </MenuItem>
                          ))}
                        </SelectMui>
                      </FormControl>
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>

              <CardFooter>
                <Button id="btnGreenAdd" type="submit" color="warning">
                  Save
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          id="settingSection-TransitInfo"
          style={{ display: transitSettingVisible ? "block" : "none" }}
        >
          <Card>
            <form enctype="multipart/form-data" id="transitInfo">
              <CardHeader className="cardheader" color="warning">
                <h4 className={classes.cardTitleWhite}>TransIT Information</h4>
              </CardHeader>
              <CardBody>
                {localStorage.getItem("role") === "admin" ? (
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      Sandbox / Live
                      <Switch
                        checked={transItPlateform}
                        onChange={(e) => {
                          document.getElementById(
                            "newtransitInfo"
                          ).style.display = "block";
                          document.getElementById(
                            "transitSettingInfo"
                          ).style.display = "none";
                          document.getElementById("transitPass").style.display =
                            "none";
                          setTransItPlateform(e.target.checked);
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                ) : (
                  ""
                )}

                <div id="newtransitInfo" style={{ display: "none" }}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Merchant ID"
                        id="MerchantID"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={newTransitMerchantId}
                        onChange={(e) =>
                          setNewTransitMerchantId(e.target.value)
                        }
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="MultiPASS Device ID"
                        id="DeviceID"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={newTransitdeviceId}
                        onChange={(e) => setNewTransitDeviceId(e.target.value)}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="User ID"
                        id="userID"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={newTransituserId}
                        onChange={(e) => setNewTransitUserId(e.target.value)}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Password"
                        id="password"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={newTransitPassword}
                        onChange={(e) => setNewTransitPassword(e.target.value)}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Developer ID"
                        id="developerID"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={newTransitDeveloperId}
                        onChange={(e) =>
                          setNewTransitDeveloperId(e.target.value)
                        }
                      />
                    </GridItem>
                    
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="TSEP Device ID"
                        id="TsepDeviceID"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={newTransittsepdeviceId}
                        onChange={(e) =>
                          setNewTransitTSEPDeviceId(e.target.value)
                        }
                      />
                    </GridItem>
                  </GridContainer>
                  <CardFooter>
                    {localStorage.getItem("role") === "admin" ? (
                      <Button
                        id="btnGreenAdd"
                        onClick={(e) => {
                          localStorage.getItem("role") === "admin"
                            ? handleNewTransitSubmit(e)
                            : "";
                        }}
                        color="warning"
                      >
                        Update
                      </Button>
                    ) : (
                      ""
                    )}
                  </CardFooter>
                </div>
                <div id="transitSettingInfo">
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Merchant ID"
                        id="MerchantID"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={transitMerchantId}
                        onChange={(e) =>
                          localStorage.getItem("role") === "admin"
                            ? setTransitMerchantId(e.target.value)
                            : ""
                        }
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="MultiPASS Device ID"
                        id="DeviceID"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={transitdeviceId}
                        onChange={(e) =>
                          localStorage.getItem("role") === "admin"
                            ? setTransitDeviceId(e.target.value)
                            : ""
                        }
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="User ID"
                        id="userID"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={transituserId}
                        onChange={(e) =>
                          localStorage.getItem("role") === "admin"
                            ? setTransitUserId(e.target.value)
                            : ""
                        }
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    {localStorage.getItem("role") === "admin" ? (
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Developer ID"
                          id="developerID"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={transitDeveloperId}
                          onChange={(e) =>
                            localStorage.getItem("role") === "admin"
                              ? setTransitDeveloperId(e.target.value)
                              : ""
                          }
                        />
                      </GridItem>
                    ) : (
                      " "
                    )}
                    {localStorage.getItem("role") === "admin" &&
                    transitChkMerchantId === null &&
                    transitChkuserId === null ? (
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="TransIT Password"
                          id="transit Password"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={transitPassword}
                          onChange={(e) =>
                            localStorage.getItem("role") === "admin"
                              ? setTransitPassword(e.target.value)
                              : ""
                          }
                        />
                      </GridItem>
                    ) : (
                      ""
                    )}
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="TSEP Device ID"
                        id="TSEPDeviceID"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={transittsepdeviceId}
                        onChange={(e) =>
                          localStorage.getItem("role") === "admin"
                            ? setTransitTSEPDeviceId(e.target.value)
                            : ""
                        }
                      />
                    </GridItem>
                  </GridContainer>
                  <CardFooter>
                    {localStorage.getItem("role") === "admin" ? (
                      <Button
                        id="btnGreenAdd"
                        onClick={(e) => {
                          localStorage.getItem("role") === "admin"
                            ? handleTransitSubmit(e)
                            : "";
                        }}
                        color="warning"
                      >
                        Save
                      </Button>
                    ) : (
                      ""
                    )}
                  </CardFooter>
                </div>
              </CardBody>
              <div id="transitPass">
                {localStorage.getItem("role") === "admin" ? (
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <b>Update TransIT Password</b>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          type="password"
                          autocomplete="off"
                          labelText="New Password"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={password}
                          onChange={(e) =>
                            localStorage.getItem("role") === "admin"
                              ? setPassword(e.target.value)
                              : ""
                          }
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          type="password"
                          autocomplete="off"
                          labelText="Confirm New Password"
                          id="DeviceID"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={confirmPassword}
                          onChange={(e) =>
                            localStorage.getItem("role") === "admin"
                              ? setConfirmPassword(e.target.value)
                              : ""
                          }
                        />
                      </GridItem>
                    </GridContainer>
                    <CardFooter>
                      <Button
                        id="btnGreenAdd"
                        onClick={(e) => {
                          localStorage.getItem("role") === "admin"
                            ? handlePasswordUpdateSubmit(e)
                            : "";
                        }}
                        color="warning"
                      >
                        Update Password
                      </Button>
                    </CardFooter>
                  </CardBody>
                ) : (
                  ""
                )}
              </div>
            </form>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          ms={12}
          id="settingSection-CVVSetting"
          style={{ display: cvvSettingVisible ? "block" : "none" }}
        >
          <Card>
            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>CVV Settings</h4>
            </CardHeader>
            <CardBody>
              <b>Reject a Transaction If...</b>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControlLabel
                    style={{ color: "black" }}
                    control={
                      <Checkbox
                        checked={cvvCodeN}
                        onChange={(e) => setCvvCodeN(e.target.checked)}
                      />
                    }
                    label="Does NOT Match (N)"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControlLabel
                    style={{ color: "black" }}
                    control={
                      <Checkbox
                        checked={cvvCodeP}
                        onChange={(e) => setCvvCodeP(e.target.checked)}
                      />
                    }
                    label="Is NOT Processed or CVV was not Provided (P)"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControlLabel
                    style={{ color: "black" }}
                    control={
                      <Checkbox
                        checked={cvvCodeS}
                        onChange={(e) => setCvvCodeS(e.target.checked)}
                      />
                    }
                    label="Should be on card, but is not indicated (S)"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControlLabel
                    style={{ color: "black" }}
                    control={
                      <Checkbox
                        checked={cvvCodeU}
                        onChange={(e) => setCvvCodeU(e.target.checked)}
                      />
                    }
                    label="Issuer is not certified or has not provided Encryption key (U)"
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button
                id="btnGreenAdd"
                onClick={voidCodeRuleSubmit}
                color="warning"
              >
                Save
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          ms={12}
          id="settingSection-AVSSetting"
          style={{ display: avsSettingVisible ? "block" : "none" }}
        >
          <Card>
            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>AVS Settings</h4>
            </CardHeader>
            <CardBody>
              <b>Reject a Transaction If...</b>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControlLabel
                    style={{ color: "black" }}
                    control={
                      <Checkbox
                        checked={avsCodeA}
                        onChange={(e) => setAVSCodeA(e.target.checked)}
                      />
                    }
                    label="A - Address: Match & 5 Digit Zip: No Match"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControlLabel
                    style={{ color: "black" }}
                    control={
                      <Checkbox
                        checked={avsCodeB}
                        onChange={(e) => setAVSCodeB(e.target.checked)}
                      />
                    }
                    label="B - International Address: Match & Zip Code: No Match"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControlLabel
                    style={{ color: "black" }}
                    control={
                      <Checkbox
                        checked={avsCodeC}
                        onChange={(e) => setAVSCodeC(e.target.checked)}
                      />
                    }
                    label="C - Global Non-AVS Participant"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControlLabel
                    style={{ color: "black" }}
                    control={
                      <Checkbox
                        checked={avsCodeD}
                        onChange={(e) => setAVSCodeD(e.target.checked)}
                      />
                    }
                    label="D - International Address: Match & Zip: Match"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControlLabel
                    style={{ color: "black" }}
                    control={
                      <Checkbox
                        checked={avsCodeF}
                        onChange={(e) => setAVSCodeF(e.target.checked)}
                      />
                    }
                    label="F - UK Address: Match & Zip: Match"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControlLabel
                    style={{ color: "black" }}
                    control={
                      <Checkbox
                        checked={avsCodeG}
                        onChange={(e) => setAVSCodeG(e.target.checked)}
                      />
                    }
                    label="G - Global Non-AVS Participant"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControlLabel
                    style={{ color: "black" }}
                    control={
                      <Checkbox
                        checked={avsCodeI}
                        onChange={(e) => setAVSCodeI(e.target.checked)}
                      />
                    }
                    label="I - Global Non-AVS Participant"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControlLabel
                    style={{ color: "black" }}
                    control={
                      <Checkbox
                        checked={avsCodeM}
                        onChange={(e) => setAVSCodeM(e.target.checked)}
                      />
                    }
                    label="M - International Address: Match & Zip Code: Not Compatible"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControlLabel
                    style={{ color: "black" }}
                    control={
                      <Checkbox
                        checked={avsCodeN}
                        onChange={(e) => setAVSCodeN(e.target.checked)}
                      />
                    }
                    label="N - Address: No Match & 5 Digit Zip: No Match"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControlLabel
                    style={{ color: "black" }}
                    control={
                      <Checkbox
                        checked={avsCodeP}
                        onChange={(e) => setAVSCodeP(e.target.checked)}
                      />
                    }
                    label="P - International Address: Not Compatible & Zip: Match"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControlLabel
                    style={{ color: "black" }}
                    control={
                      <Checkbox
                        checked={avsCodeR}
                        onChange={(e) => setAVSCodeR(e.target.checked)}
                      />
                    }
                    label="R - Retry / System Unavailable"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControlLabel
                    style={{ color: "black" }}
                    control={
                      <Checkbox
                        checked={avsCodeS}
                        onChange={(e) => setAVSCodeS(e.target.checked)}
                      />
                    }
                    label="S - Service Not Supported"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControlLabel
                    style={{ color: "black" }}
                    control={
                      <Checkbox
                        checked={avsCodeU}
                        onChange={(e) => setAVSCodeU(e.target.checked)}
                      />
                    }
                    label="U - Retry / System Unavailable"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControlLabel
                    style={{ color: "black" }}
                    control={
                      <Checkbox
                        checked={avsCodeW}
                        onChange={(e) => setAVSCodeW(e.target.checked)}
                      />
                    }
                    label="W - Address: No Match & 9-Digit Zip: Match"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControlLabel
                    style={{ color: "black" }}
                    control={
                      <Checkbox
                        checked={avsCodeX}
                        onChange={(e) => setAVSCodeX(e.target.checked)}
                      />
                    }
                    label="X - Address: Match & 9 Digit Zip: Match"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControlLabel
                    style={{ color: "black" }}
                    control={
                      <Checkbox
                        checked={avsCodeY}
                        onChange={(e) => setAVSCodeY(e.target.checked)}
                      />
                    }
                    label="Y - Address: Match & 5 Digit Zip: Match"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControlLabel
                    style={{ color: "black" }}
                    control={
                      <Checkbox
                        checked={avsCodeZ}
                        onChange={(e) => setAVSCodeZ(e.target.checked)}
                      />
                    }
                    label="Z - Address: No Match & 5 Digit Zip: Match"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControlLabel
                    style={{ color: "black" }}
                    control={
                      <Checkbox
                        checked={avsCode0}
                        onChange={(e) => setAVSCode0(e.target.checked)}
                      />
                    }
                    label="0 - Address verification was not requested."
                  />
                </GridItem>
                <hr
                  style={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    color: "black",
                    backgroundColor: "black",
                    height: 0.5,
                    width: "98%",
                  }}
                />
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ marginBottom: "10px" }}
                >
                  <b>For AMEX Card Only...</b>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <FormControlLabel
                    style={{ color: "black" }}
                    control={
                      <Checkbox
                        checked={avsCode1}
                        onChange={(e) => setAVSCode1(e.target.checked)}
                      />
                    }
                    label="1 - The cardholder name and ZIP code submitted matches what is on file."
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControlLabel
                    style={{ color: "black" }}
                    control={
                      <Checkbox
                        checked={avsCode2}
                        onChange={(e) => setAVSCode2(e.target.checked)}
                      />
                    }
                    label="2 - The cardholder name, address, and ZIP code submitted match what is on file."
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControlLabel
                    style={{ color: "black" }}
                    control={
                      <Checkbox
                        checked={avsCode3}
                        onChange={(e) => setAVSCode3(e.target.checked)}
                      />
                    }
                    label="3 - The cardholder name and address submitted match what is on file."
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControlLabel
                    style={{ color: "black" }}
                    control={
                      <Checkbox
                        checked={avsCode4}
                        onChange={(e) => setAVSCode4(e.target.checked)}
                      />
                    }
                    label="4 - The cardholder name submitted match what is on file."
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControlLabel
                    style={{ color: "black" }}
                    control={
                      <Checkbox
                        checked={avsCode5}
                        onChange={(e) => setAVSCode5(e.target.checked)}
                      />
                    }
                    label="5 - The cardholder name does not match what is on file. The ZIP code submitted matches what is on file."
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControlLabel
                    style={{ color: "black" }}
                    control={
                      <Checkbox
                        checked={avsCode6}
                        onChange={(e) => setAVSCode6(e.target.checked)}
                      />
                    }
                    label="6 - The cardholder name does not match what is on file. The address and ZIP code submitted matches what is on file."
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControlLabel
                    style={{ color: "black" }}
                    control={
                      <Checkbox
                        checked={avsCode7}
                        onChange={(e) => setAVSCode7(e.target.checked)}
                      />
                    }
                    label="7 - The cardholder name does not match what is on file. The address submitted matches what is on file."
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControlLabel
                    style={{ color: "black" }}
                    control={
                      <Checkbox
                        checked={avsCode8}
                        onChange={(e) => setAVSCode8(e.target.checked)}
                      />
                    }
                    label="8 - Neither the cardholder name, address, or ZIP code match what is on file."
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button
                id="btnGreenAdd"
                onClick={voidCodeRuleSubmit}
                color="warning"
              >
                Save
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>

      {/*
       * display IVU form settings
       */}
      {purtoNumberHtml}
      <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          id="settingSection-ReceiptLayout"
          style={{ display: receiptSettingVisible ? "block" : "none" }}
        >
          <Card>
            {/**
             * Form receipt layout settings
             */}
            <form onSubmit={handleReceiptLayoutSubmit}>
              <CardHeader className="cardheader" color="warning">
                <h4 className={classes.cardTitleWhite}>Receipt Layout</h4>
              </CardHeader>

              <CardBody>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel2
                    id="demo-simple-select-label"
                    style={{
                      color: "rgba(0, 0, 0, 0.87)",
                      fontWeight: "300",
                      lineHeight: "1.5em",
                      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    {getLogo ? "Company Logo" : "Company Logo"}
                  </InputLabel2>
                  <span style={{ fontSize: "10px" }}>
                    {" "}
                    [Image size should be <b>300px X 65px</b>]
                  </span>

                  <div style={{ display: "block", marginTop: "10px" }}>
                    <div>
                      <input
                        type="file"
                        multiple
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          setLogo(e.target.files[0]);
                          setGetLogo(URL.createObjectURL(e.target.files[0]));
                          if (document.getElementById("logoImage")) {
                            document.getElementById("logoImage").style.display =
                              "block";
                          }
                          if (document.getElementById("deleteLogoBtn")) {
                            document.getElementById(
                              "deleteLogoBtn"
                            ).style.display = "block";
                          }
                          if (document.getElementById("uploadLogoBtn")) {
                            document.getElementById(
                              "uploadLogoBtn"
                            ).style.display = "none";
                          }
                          if (document.getElementById("cancelLogoBtn")) {
                            document.getElementById(
                              "cancelLogoBtn"
                            ).style.display = "none";
                          }
                        }}
                        id="contained-button-file"
                      />
                      {getLogo ? (
                        <div style={{ display: "block" }}>
                          <div style={{ display: "flex" }}>
                            <img
                              id="logoImage"
                              style={{
                                display: getLogo ? "block" : "none",
                                height: "65px",
                                objectFit: "contain",
                              }}
                              src={getLogo}
                            ></img>
                            <Button
                              onClick={deleteCompanyLogo}
                              style={{
                                marginTop: "20px",
                                backgroundColor: "#ffa726",
                                color: "#FFFFFF",
                                border: "none",
                              }}
                              id="deleteLogoBtn"
                              variant="contained"
                              component="span"
                            >
                              <DeleteForeverIcon></DeleteForeverIcon>
                            </Button>
                          </div>
                          <div style={{ display: "flex" }}>
                            <label htmlFor="contained-button-file">
                              <Button
                                id="uploadLogoBtn"
                                style={{ display: "none", marginTop: "20px" }}
                                variant="contained"
                                color="success"
                                component="span"
                              >
                                Upload
                              </Button>
                            </label>
                            <label>
                              <Button
                                onClick={cancelCompanyLogo}
                                id="cancelLogoBtn"
                                style={{
                                  display: "none",
                                  marginTop: "20px",
                                  marginLeft: "10px",
                                }}
                                variant="contained"
                                color="warning"
                                component="span"
                              >
                                Cancel
                              </Button>
                            </label>
                          </div>
                        </div>
                      ) : (
                        <label htmlFor="contained-button-file">
                          <Button
                            id="uploadLogoBtn"
                            style={{ marginTop: "20px" }}
                            variant="contained"
                            color="success"
                            component="span"
                          >
                            Upload
                          </Button>
                        </label>
                      )}
                    </div>
                  </div>
                </GridItem>
                <br></br>
                <GridItem xs={12} sm={12} md={6}>
                  Show Signature Line
                  <Switch
                    checked={signFooterSwitch}
                    onChange={(e) => {
                      e.target.checked
                        ? (document.getElementById("signFooter").style.display =
                            "block")
                        : (document.getElementById("signFooter").style.display =
                            "none");
                      setSignFooterSwitch(e.target.checked);
                    }}
                  />
                  <div
                    id="signFooter"
                    style={{ display: signFooterSwitch ? "block" : "none" }}
                  >
                    <CustomInput
                      labelText="Signature Footer Line Text"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      value={sign_footer_line}
                      onChange={(e) =>
                        setSignFooterLine(
                          e.target.value.replace(/[^a-z1-9!@,. ]/gi, "")
                        )
                      }
                    />
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Footer Line"
                    id="taxidnumber"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={footer_line}
                    onChange={(e) =>
                      setFooterLine(
                        e.target.value.replace(/[^a-z1-9!@,. ]/gi, "")
                      )
                    }
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  Print Customer Name on Receipt
                  <Switch
                    checked={print_customer_name}
                    onChange={(e) => {
                      setPrintCustomerName(e.target.checked);
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  Print Table Number on Receipt
                  <Switch
                    checked={print_table_number}
                    onChange={(e) => {
                      setPrintTableNumber(e.target.checked);
                    }}
                  />
                </GridItem>
              </CardBody>

              <CardFooter>
                <Button id="btnGreenAdd" type="submit" color="warning">
                  Save
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          id="settingSection-BatchSetting"
          style={{ display: batchSettingVisible ? "block" : "none" }}
        >
          <Card>
            {/**
             * Form receipt layout settings
             */}
            <form onSubmit={handleReceiptLayoutSubmit}>
              <CardHeader className="cardheader" color="warning">
                <h4 className={classes.cardTitleWhite}>Batch Setting</h4>
              </CardHeader>

              <CardBody>
                <GridItem xs={12} sm={12} md={6}>
                  Get Email Notification Every Batch
                  <Switch
                    checked={batchAtoEmail === "0" ? false : batchAtoEmail}
                    onChange={(e) => {
                      e.target.checked
                        ? (document.getElementById("emailSec").style.display =
                            "block")
                        : (document.getElementById("emailSec").style.display =
                            "none");
                      setBatchAutoEmail(e.target.checked);
                    }}
                  />
                  <div
                    id="emailSec"
                    style={{
                      display:
                        batchAtoEmail === false || batchAtoEmail === "0"
                          ? "none"
                          : "block",
                    }}
                  >
                    <CustomInput
                      labelText="Enater Email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      value={
                        batchAtoEmail === true ||
                        batchAtoEmail === false ||
                        batchAtoEmail === "0"
                          ? ""
                          : batchAtoEmail
                      }
                      onChange={(e) => setBatchAutoEmail(e.target.value)}
                    />
                  </div>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={6}
                  style={{
                    display: processor == "TSYS TransIT" ? "block" : "none",
                  }}
                >
                  <FormControl
                    className={classes.margintopid}
                    sx={{ width: "100%" }}
                  >
                    <InputLabel id="demo-simple-select-helper-label">
                      Auto Batch Close At
                    </InputLabel>
                    <SelectMui
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={autoBatchCloseTime}
                      label="Auto Batch Close At"
                      onChange={(e) => setAutoBatchCloseTime(e.target.value)}
                    >
                      {/* <MenuItem value={""}></MenuItem> */}
                      {Object.entries(autoCloseBatchTime).map(
                        ([key, value]) => (
                          <MenuItem value={value}>{value}</MenuItem>
                        )
                      )}
                    </SelectMui>
                  </FormControl>
                </GridItem>
              </CardBody>

              <CardFooter>
                <Button id="btnGreenAdd" type="submit" color="warning">
                  Save
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
        <Dialog
          open={disclaimerDialog}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          style={{ padding: "0px" }}
        >
          <DialogTitle id="alert-dialog-slide-title">Disclaimer</DialogTitle>
          <DialogContent>
            Using Cash Discount feature is at your own risk. Merchant is
            required to follow local regulatory guidelines and confirm that Cash
            Discount is legal and allowed in your jurisdiction. Merchant is
            responsible to confirm that this function is compliant with all
            local and state laws. MagicPay is not responsible for misusing this
            feature and would not take any responsibility of any consequences
            that arise of using this function.
            <br /> Beginning April 15, 2023 , merchants in the United States
            will be restricted to surcharge customers to a 3% cap for those who
            pay with Visa credit cards.
          </DialogContent>

          <DialogActions>
            <Button
              id="btnGreyCancel"
              onClick={(e) => {
                setDisclaimerDialog(false);
              }}
              color="primary"
              style={{ backgroundColor: "#ffa726", color: "#FFFFFF" }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </GridContainer>

      <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          id="settingSection-ReportSetting"
          style={{ display: reportSettingVisible ? "block" : "none" }}
        >
          <Card>
            {/**
             * Form default report time
             */}
            <form
              onSubmit={handleReportTimeSubmit}
              enctype="multipart/form-data"
            >
              <CardHeader className="cardheader" color="warning">
                <h4 className={classes.cardTitleWhite}>Report Settings</h4>
              </CardHeader>
              <CardBody>
                <GridItem xs={12} sm={12} md={6} style={{ marginTop: "20px" }}>
                  <div class="halfhourtimepicker">
                    <InputLabel2 id="demo-simple-select-label">
                      Default Start Time
                    </InputLabel2>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                    />
                  </div>
                </GridItem>
              </CardBody>

              <CardFooter>
                <Button id="btnGreenAdd" type="submit" color="warning">
                  Save
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          id="settingSection-OtherSetting"
          style={{ display: otherSettingVisible ? "block" : "none" }}
        >
          <Card>
            {/**
             * Form other settings
             */}
            <form onSubmit={handleOtherSettingSubmit}>
              <CardHeader className="cardheader" color="warning">
                <h4 className={classes.cardTitleWhite}>Other Settings</h4>
              </CardHeader>
              <CardBody>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <GridItem xs={12} sm={12} md={6}>
                    <Switch
                      checked={accept_debit_cards}
                      onChange={(e) => {
                        setAcceptDebitCard(e.target.checked);
                      }}
                    />
                    Accept Debit Card
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Switch
                      checked={accept_credit_cards}
                      onChange={(e) => {
                        setAcceptCreditCard(e.target.checked);
                      }}
                    />
                    Accept Credit Card
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Switch
                      checked={accept_cash_cards}
                      onChange={(e) => {
                        setAcceptCash(e.target.checked);
                      }}
                    />
                    Accept Cash Payment
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Switch
                      checked={accept_ebt}
                      onChange={(e) => {
                        setAcceptEbt(e.target.checked);
                      }}
                    />
                    Accept EBT
                  </GridItem>

                  <GridItem
                    xs={12}
                    sm={12}
                    md={6}
                    style={{
                      display:
                        enableATHBusiness &&
                        (state == "Puerto Rico" || state == "US Virgin Islands")
                          ? "block"
                          : "none",
                    }}
                  >
                    <Switch
                      checked={enableATHBusiness}
                      onChange={(e) => {
                        handleEnableATHBusiness(e.target.checked);
                      }}
                    />
                    Accept ATH Movil
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <Switch
                      checked={custom_tab}
                      onChange={(e) => {
                        if (e.target.value == false && item_tab == false) {
                          setError(true),
                            setErrorMsg(
                              "Show Item Tab or Show Custom Tab must be cheched.it is not possible to disable both options."
                            );
                        } else {
                          setShowCustomTab(e.target.checked);
                        }
                      }}
                    />
                    Show Custom Tab
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Switch
                      checked={item_tab}
                      onChange={(e) => {
                        if (e.target.value == false && custom_tab == false) {
                          setError(true),
                            setErrorMsg(
                              "Show Item Tab or Show Custom Tab must be cheched.it is not possible to disable both options."
                            );
                        } else {
                          setShowItemTab(e.target.checked);
                        }
                      }}
                    />
                    Show Item Tab
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Switch
                      checked={enable_quick_payment}
                      onChange={(e) => {
                        setEnableQuickPayment(e.target.checked);
                      }}
                    />
                    Show Quick Payment
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Switch
                      checked={include_tax}
                      onChange={(e) => {
                        setIncludeTax(e.target.checked);
                      }}
                    />
                    Item Price Include Tax
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Switch
                      checked={ask_customer_name}
                      onChange={(e) => {
                        setAskCustomerName(e.target.checked);
                      }}
                    />
                    Prompt Customer Name
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <Switch
                      checked={ask_table_number}
                      onChange={(e) => {
                        setAskTableNumber(e.target.checked);
                      }}
                    />
                    Prompt Table Number
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <Switch
                      checked={promptSignatureonPINDebit}
                      onChange={(e) => {
                        setPromptSignatureonPINDebit(e.target.checked);
                      }}
                    />
                    Prompt Signature on PIN Debit
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={6}
                    style={{
                      display: processor == "TSYS TransIT" ? "block" : "none",
                    }}
                  >
                    <Switch
                      checked={sendPaymentLink}
                      onChange={(e) => {
                        setSendPaymentLink(e.target.checked);
                      }}
                    />
                    Send Payment Link
                  </GridItem>
                </FormControl>
                <GridItem
                  id="gidaterange"
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ marginTop: "16px" }}
                >
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    <InputLabel2 id="demo-simple-select-helper-label">
                      Card Holder Signature
                    </InputLabel2>
                    <MuiSelect
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={cardHolderSignature}
                      label="Card Holder Signature"
                      onChange={(e) => setCardHolderSignature(e.target.value)}
                    >                     
                      <MenuItem value="Disabled">Disabled</MenuItem>
                      <MenuItem value="Digital Signature">
                        Digital Signature
                      </MenuItem>
                      <MenuItem value="Paper Signature">
                        Paper Signature
                      </MenuItem>
                    </MuiSelect>
                  </FormControl>
                </GridItem>
              </CardBody>

              <CardFooter>
                <Button id="btnGreenAdd" type="submit" color="warning">
                  Save
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          id="settingSection-CashDiscProgram"
          style={{ display: cashDiscSettingVisible ? "block" : "none" }}
        >
          <Card>
            {/**
             * Form other settings
             */}
            <form onSubmit={handlecashDiscountProgramSubmit}>
              <CardHeader className="cardheader" color="warning">
                <h4 className={classes.cardTitleWhite}>
                  Cash Discount Program
                </h4>
              </CardHeader>
              <CardBody>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <GridItem xs={12} sm={12} md={6}>
                    Set Cash Discount Program
                    <Switch
                      checked={enableCashDiscountProgram}
                      onChange={(e) => {
                        handleSetCashDiscount(e.target.checked);
                      }}
                    />
                  </GridItem>
                </FormControl>
                <GridItem xs={12} sm={12} md={3}>
                  <FormControl id="tfDiscount" sx={{ m: 1, width: "100%" }}>
                    {/* Ammount from textfield */}
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      margin="full"
                      onChange={(e) => validateCashDiscountValue(e)}
                      value={cashDiscountAmount}
                      label="Set Cash Discount Percentage"
                      style={{ width: "100%" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <PrecentageIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </GridItem>
                <FormControl id="swAllowRemoveFee" sx={{ m: 1, width: "100%" }}>
                  <GridItem xs={12} sm={12} md={6}>
                    Allow To Remove Fee
                    <Switch
                      checked={remove_fee_chk_box}
                      onChange={(e) => {
                        setRemoveFee(e.target.checked);
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    Apply Fee to Credit Cards
                    <Switch
                      checked={applyFeeCreditCard}
                      onChange={(e) => {
                        setApplyFeeCreditCard(e.target.checked);
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    Apply Fee to Debit Cards
                    <Switch
                      checked={applyFeeDebitCard}
                      onChange={(e) => {
                        setApplyFeeDebitCard(e.target.checked);
                      }}
                    />
                  </GridItem>
                </FormControl>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <GridItem xs={12} sm={12} md={6}>
                    <h4 className={classes.disclaimerTitle}>
                      Disclaimer:{" "}
                      
                      <QuestionMarkIcon
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                      onClick={(e) => {
                        setDisclaimerDialog(true);
                      }}
                      ></QuestionMarkIcon>
                      
                    </h4>
                  </GridItem>
                </FormControl>
              </CardBody>

              <CardFooter>
                <Button id="btnGreenAdd" type="submit" color="warning">
                  Save
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          id="settingSection-ATHBusinessSetting"
          style={{ display: athSettingVisible ? "block" : "none" }}
        >
          <Card>
            {/**
             * Form ATH Movil
             */}
            <form onSubmit={handleATHSettingSubmit}>
              <CardHeader className="cardheader" color="warning">
                <h4 className={classes.cardTitleWhite}>
                  ATH Business Settings
                </h4>
              </CardHeader>
              <CardBody>
                <GridItem xs={12} sm={12} md={6}>
                  <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                    Enable ATH Business
                    <Switch
                      checked={enableATHBusiness}
                      onChange={(e) => {
                        handleEnableATHBusiness(e.target.checked);
                      }}
                    />
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      setQrCode(e.target.files[0]);
                      setGetQrLogo(URL.createObjectURL(e.target.files[0]));
                      document.getElementById(
                        "labelForQrCodeUploadBtn"
                      ).style.display = "none";
                      document.getElementById("qrCodeImage").style.display =
                        "block";
                      document.getElementById(
                        "deleteQrCodeImageBtn"
                      ).style.display = "block";
                    }}
                    id="QRCodeUpload"
                  />

                  <label id="labelForQrCodeUploadBtn" htmlFor="QRCodeUpload">
                    <Button
                      variant="contained"
                      color="success"
                      component="span"
                    >
                      Upload QR Code
                    </Button>
                  </label>

                  <div id="qrCodeImageDiv" style={{ display: "flex" }}>
                    <img
                      id="qrCodeImage"
                      style={{
                        display: qrCode ? "block" : "none",
                        height: "65px",
                        objectFit: "contain",
                      }}
                      src={getQrLogo}
                    ></img>
                    <Button
                      onClick={deleteQrCodeImage}
                      style={{
                        marginTop: "20px",
                        backgroundColor: "#ffa726",
                        color: "#FFFFFF",
                        border: "none",
                        display: qrCode ? "block" : "none",
                      }}
                      id="deleteQrCodeImageBtn"
                      variant="contained"
                      component="span"
                    >
                      <DeleteForeverIcon></DeleteForeverIcon>
                    </Button>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={7}>
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    <div>
                      <div>
                        <span style={{ fontSize: "10px" }}>
                          Go to the link below to get your QR Code:
                        </span>
                      </div>
                      <div style={{ marginTop: "-10px" }}>
                        <a
                          style={{ fontSize: "10px", cursor: "pointer" }}
                          target="_blank"
                          href="https://ath.business/en/materials"
                        >
                          https://ath.business/en/materials
                        </a>
                      </div>
                    </div>
                  </FormControl>
                </GridItem>
                <GridItem id="inputATHBusinessPath" xs={12} sm={12} md={7}>
                  <FormControl
                    style={{ marginTop: "40px" }}
                    id="tfDiscount"
                    sx={{ m: 1, width: "100%" }}
                  >
                    {/* Ammount from textfield */}
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      margin="full"
                      onChange={(e) => {
                        setATHPath(e.target.value);
                        setATHWebhookURL(
                          "https://api.magicpaypos.com/api/ath/" +
                            e.target.value
                        );
                      }}
                      value={ath_path}
                      label="ATH Business Path"
                      style={{ width: "100%" }}
                    />
                  </FormControl>
                </GridItem>

                <GridItem id="inputATHWebhookURL" xs={12} sm={12} md={7}>
                  <FormControl
                    style={{ marginTop: "20px" }}
                    id="tfDiscount"
                    sx={{ m: 1, width: "100%" }}
                  >
                    {/* Ammount from textfield */}
                    <TextField
                      id="outlined-basic disableATHWebUrl"
                      variant="outlined"
                      disabled
                      margin="full"
                      value={ath_webhook_url}
                      label="Your ATH Webhook URL"
                      style={{ width: "100%" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <img
                              onClick={(e) => {
                                navigator.clipboard.writeText(ath_webhook_url);
                                setSuccessMsg("Copied!");
                                setSuccess(true);
                              }}
                              style={{
                                height: "30px",
                                width: "30px",
                                paddingLeft: "15px",
                                cursor: "pointer",
                              }}
                              src="/copy_icon.png"
                            />
                            <img
                              onClick={handleSMSClick}
                              style={{
                                height: "25px",
                                width: "25px",
                                paddingLeft: "15px",
                                cursor: "pointer",
                              }}
                              src="/message.png"
                            />
                            <img
                              onClick={(e) => {
                                setEmailDailog(true);
                              }}
                              style={{
                                height: "20px",
                                width: "25px",
                                paddingLeft: "15px",
                                paddingBottom: "5px",
                                cursor: "pointer",
                              }}
                              src="/envelope.png"
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </GridItem>

                <GridItem id="inputATHPublicToken" xs={12} sm={12} md={7}>
                  <FormControl
                    style={{ marginTop: "20px" }}
                    id="tfDiscount"
                    sx={{ m: 1, width: "100%" }}
                  >
                    {/* Ammount from textfield */}
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      margin="full"
                      value={ath_public_token}
                      onChange={(e) => setATHPublicToken(e.target.value)}
                      label="ATH Public Token"
                      style={{ width: "100%" }}
                    />
                  </FormControl>
                </GridItem>

                <GridItem id="inputATHPrivateToken" xs={12} sm={12} md={7}>
                  <FormControl
                    style={{ marginTop: "20px" }}
                    id="tfDiscount"
                    sx={{ m: 1, width: "100%" }}
                  >
                    {/* Ammount from textfield */}
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      margin="full"
                      value={ath_private_token}
                      onChange={(e) => setATHPrivateToken(e.target.value)}
                      label="ATH Private Token"
                      style={{ width: "100%" }}
                    />
                  </FormControl>
                </GridItem>
              </CardBody>

              <CardFooter>
                <Button id="btnGreenAdd" type="submit" color="warning">
                  Save
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
        <Dialog
          // classes={{ paper: classes.paper }}
          open={zipcodeLoding} onClose={() => { }} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Getting Information..</DialogTitle>
          <DialogContent>
            <GridContainer >
            <GridItem xs={12} sm={12} md={12}>
               <center><CircularProgress id="loginProgress" ></CircularProgress></center>
            </GridItem>
            </GridContainer>

          </DialogContent>         
        </Dialog>
      </GridContainer>
    </div>
  );
}
